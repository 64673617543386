import {
  Button,
  ButtonGroup,
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { SimpleCard } from "matx";
import React, {
  Component,
  Fragment,
  useEffect,
  useState,
  useLayoutEffect,
} from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { formatTanggal } from "app/utils/globalFunction";
import {
  getTunjangan,
  delTunjangan,
} from "app/redux/actions/gaji/TunjanganAction";
import AbsensiForm from "../absensi/AbsensiForm";
import { Link } from "react-router-dom";
import { MatxMenu } from "matx";
import Aksi from "../../../assets/component/Aksi.svg";
import { RenderTable } from "app/components";
import { SelectText } from "app/components";
import AddIcon from "@material-ui/icons/Add";

const Tunjangan = ({ dataTunjangan, getTunjangan, delTunjangan }) => {
  const [state, setState] = useState({
    loading: true,
    search: "",
    searchTgl: 0,
    page: 0,
    rowsPerPage: 10,
    selectedItem: "",
    dataLanguage: [
      {
        label: "Indonesia",
        value: "ind",
      },
      {
        label: "English",
        value: "eng",
      },
    ],
    bahasa: "",
  });

  const setPage = (page) => {
    setState({ ...state, page });
  };

  const setRowsPerPage = (event) => {
    setState({ ...state, rowsPerPage: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");

  const getData = () => {
    let params = `?search=${search}&jenis=${status}`;

    if (search === "") {
      params = `?jenis=${status}`;
    } else if (status === "") {
      params = `?search=${search}`;
    }

    getTunjangan(params);
  };

  console.log(status);

  useEffect(() => {
    getData();
  }, [status]);

  useLayoutEffect(() => {
    getData();
  }, []);

  const submitSearch = (e) => {
    if (e.keyCode == 13) {
      getData();
    }
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Hapus",
      text: "Apakah kamu yakin ?",
      showCancelButton: true,
      confirmButtonText: "Yakin",
      cancelButtonText: "Batal",
      icon: "warning",
    }).then((res) => {
      if (res.isConfirmed) {
        delTunjangan(id)
          .then((res) => {
            if (res.code === 0) {
              Swal.fire({
                title: "Berhasil",
                text: "Data berhasil dihapus",
                timer: 2000,
                icon: "success",
              });
              getData();
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "gagal",
              text: "Data Gagal dihapus",
              timer: 2000,
              icon: "error",
            });
          });
      }
    });
  };

  const handleChange = (e) => {
    setState({
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="m-sm-30">
      <Grid container spacing={1} justify="space-between" className="my-4 mb-8">
        <h1 className="mt-4 font-semibold text-25 ">Tunjangan</h1>

        <div className="tambah-button">
          <Link to={`/Tunjangan/Tambah`}>
            <Button>
              <AddIcon />
              <div style={{ marginLeft: "5px" }}>Tambah</div>
            </Button>
          </Link>
        </div>
      </Grid>
      <SimpleCard loading={false} currency="" saldo="">
        <Fragment>
          <div
            className="d-flex items-center py-4 "
            style={{ justifyContent: "flex-end", gap: "20px" }}
          >
            <TextField
              size="small"
              variant="outlined"
              className="karyawan-top-search"
              placeholder="Cari Nama Karyawan"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              onKeyDown={submitSearch}
              name="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
              }}
            />

            <Grid item xs={3} sm={2} className="d-flex align-items-end">
              <SelectText
                search
                isJenisTunjangan
                label="Jenis"
                value={status}
                handleChange={handleStatus}
              />
            </Grid>
          </div>
          <div className="w-full overflow-auto bg-white">
            <Table
              className="buku-kas-table"
              style={{
                borderTop: "1px #e6e5e5 solid",
                marginTop: "20px",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>No</TableCell>
                  <TableCell colSpan={2}>Nama</TableCell>
                  <TableCell align="center" colSpan={4}>
                    Jenis Tunjangan
                  </TableCell>

                  <TableCell align="center" colSpan={4}>
                    Nominal
                  </TableCell>
                  <TableCell align="center" colSpan={4}>
                    Tanggal
                  </TableCell>
                  <TableCell align="center" colSpan={3}>
                    Aksi
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <RenderTable
                  state={state}
                  data={dataTunjangan}
                  tableName="tunjangan"
                  handleDelete={handleDelete}
                  dataKosongSpan={19}
                />
              </TableBody>
            </Table>
            <TablePagination
              className="px-16"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataTunjangan?.length ? dataTunjangan?.length : 0}
              rowsPerPage={state.rowsPerPage}
              labelRowsPerPage={"From"}
              page={state.page}
              backIconButtonProps={{
                "aria-label": "Previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next page",
              }}
              backIconButtonText="Previous page"
              nextIconButtonText="Next page"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={setRowsPerPage}
            />
          </div>
        </Fragment>
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataTunjangan: state.gaji.dataTunjangan,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTunjangan: (params) => dispatch(getTunjangan(params)),
    delTunjangan: (params) => dispatch(delTunjangan(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Tunjangan);
