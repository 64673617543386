import { API, setAuthToken } from 'app/config/api';

export const GET_DATA_ABSEN = 'GET_DATA_ABSEN';
export const GET_DATA_LOKASI_ABSENSI = 'GET_DATA_LOKASI_ABSENSI';
export const GET_DETAIL_LOKASI_ABSENSI = 'GET_DETAIL_LOKASI_ABSENSI';
export const GET_DATA_TITIK_ABSENSI = 'GET_DATA_TITIK_ABSENSI';
export const GET_RIWAYAT_ABSENSI_KARYAWAN = 'GET_RIWAYAT_ABSENSI_KARYAWAN';
export const GET_DETAIL_KEHADIRAN = 'GET_DETAIL_KEHADIRAN';

export const getTitikAbsensi = () => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    const res = await API.get('/user/aturan/titik_absensi').catch((err) =>
      Promise.reject(err)
    );
    if (res.data.code === 0) {
      dispatch({
        type: GET_DATA_TITIK_ABSENSI,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DATA_TITIK_ABSENSI,
        payload: [],
      });
    }
  };
};

export const getAturWaktuAbsensi = () => {
  const token = localStorage.getItem('jwt_token');
  setAuthToken(token);
  return API.get('/user/aturan/absensi');
};

export const getDataAbsen = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    const res = await API.get(`/user/absensi/absensi_karyawan${params}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: GET_DATA_ABSEN,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DATA_ABSEN,
        payload: [],
      });
    }
  };
};

export const getDataLokasiAbsensi = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    const res = await API.get(`/user/absensi/lokasi_absensi${params}`).catch(
      (err) => {
        return Promise.reject(err);
      }
    );
    if (res.data.code === 0) {
      dispatch({
        type: GET_DATA_LOKASI_ABSENSI,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DATA_LOKASI_ABSENSI,
        payload: [],
      });
    }
  };
};

export const addKaryawanAbsensi = (body) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.post(
      `/user/absensi/absensi_karyawan/add`,
      body
    ).catch((err) => {
      return Promise.reject(err);
    });

    return res.data;
  };
};

export const editKaryawanAbsensi = (body) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.post(
      `/user/absensi/absensi_karyawan/update`,
      body
    ).catch((err) => {
      return Promise.reject(err);
    });

    return res.data;
  };
};

export const addLokasiAbsensi = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.post(
      '/user/absensi/lokasi_absensi/add',
      params
    ).catch((err) => {
      return Promise.reject(err);
    });

    return res.data;
  };
};

export const editLokasiAbsensi = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const res = await API.post(
      '/user/absensi/lokasi_absensi/update',
      params
    ).catch((err) => {
      return Promise.reject(err);
    });

    return res.data;
  };
};

export const deleteLokasiAbsensi = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    const res = await API.delete(
      `/user/absensi/lokasi_absensi/delete/${params}`
    ).catch((err) => {
      return Promise.reject(err);
    });

    return res.data;
  };
};

export const getDetailLokasiAbsensi = (id) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    const res = await API.get(
      `/user/absensi/lokasi_absensi/detail/${id}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_LOKASI_ABSENSI,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_LOKASI_ABSENSI,
        payload: [],
      });
    }
  };
};

export const getRiwayatAbsensiKaryawan = (id) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    const res = await API.get(
      `/user/absensi/absensi_karyawan/kehadiran${id}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: GET_RIWAYAT_ABSENSI_KARYAWAN,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_RIWAYAT_ABSENSI_KARYAWAN,
        payload: [],
      });
    }
  };
};

export const getDetailKehadiran = (id) => {
  return async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    const res = await API.get(
      `/user/absensi/absensi_karyawan/kehadiran/detail/${id}`
    ).catch((err) => {
      return Promise.reject(err);
    });
    if (res.data.code === 0) {
      dispatch({
        type: GET_DETAIL_KEHADIRAN,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_DETAIL_KEHADIRAN,
        payload: [],
      });
    }
  };
};
