import { useState } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

export default function SimpleMenu({
  item,
  linkEdit,
  linkDetail,
  handleEdit,
  handleUnduh,
  handleDetail,
  handleDelete,
  noEdit,
  noDelete,
  noDetail,
  unduhSlipGaji,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseEdit = () => {
    setAnchorEl(null);
    handleEdit();
  };

  const handleCloseUnduh = () => {
    setAnchorEl(null);
    handleUnduh();
  };

  const handleCloseDetail = () => {
    setAnchorEl(null);
    handleDetail();
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="karyawan-aksi-dot elevation-z0"
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {noDetail ? null : (
          <div>
            {handleDetail ? (
              <MenuItem onClick={() => handleCloseDetail()}>
                <VisibilityOutlinedIcon />
                <span className="mr-3px pl-3 ">Lihat Detail</span>
              </MenuItem>
            ) : (
              <Link
                to={
                  linkDetail
                    ? linkDetail
                    : {
                        pathname: '/karyawan/detail',
                        state: { item },
                      }
                }
              >
                <MenuItem onClick={handleClose}>
                  <VisibilityOutlinedIcon className="mr-3px" />
                  <span className="mr-3px pl-3 "> Lihat Detail</span>
                </MenuItem>
              </Link>
            )}
          </div>
        )}

        {noEdit ? null : (
          <div>
            {handleEdit ? (
              <MenuItem onClick={() => handleCloseEdit()}>
                <span className="color-green">
                  <CreateOutlinedIcon />
                </span>
                <span className="mr-3px pl-3 color-green">Edit Data</span>
              </MenuItem>
            ) : unduhSlipGaji ? (
              <MenuItem
                onClick={(e) => {
                  handleCloseUnduh();
                }}
              >
                <span className="color-green">
                  <CreateOutlinedIcon />
                </span>
                <span className="mr-3px pl-3 color-green">Unduh Slip Gaji</span>
              </MenuItem>
            ) : (
              <Link
                to={
                  linkEdit
                    ? linkEdit
                    : {
                        pathname: '/karyawan/edit',
                        state: { item },
                      }
                }
              >
                <MenuItem onClick={handleClose}>
                  <span className="color-green">
                    <CreateOutlinedIcon />
                  </span>
                  <span className="mr-3px pl-3 color-green">Edit Data</span>
                </MenuItem>
              </Link>
            )}
          </div>
        )}

        {noDelete ? null : (
          <MenuItem onClick={handleClose}>
            <span onClick={handleDelete}>
              <DeleteOutlineOutlinedIcon />
              <span className="karyawan-aksi-icon">Hapus Data </span>
            </span>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
