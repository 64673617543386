import { useState, useLayoutEffect, useEffect } from 'react';
import { SimpleCard } from 'matx';
import {
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Grid,
} from '@material-ui/core';

import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';

import RenderTableDashboard from 'app/components/table/RenderTableDashboard';
import CardChartTotalPenghargaan from 'app/components/charts/CardChartTotalPenghargaan';
import DashboardSelect from './component/DashboardSelect';

import { connect } from 'react-redux';
import {
  getDataDashboardTop,
  getStatistikIzinCuti,
  getDataDashboardPermintaanIzin,
  getStatistikKeterlambatan,
} from 'app/redux/actions/DashboardActions';

const Dashboard = ({
  dataDashboardTop,
  getDataDashboardTop,
  dataDashboardPermintaanIzin,
  getDataDashboardPermintaanIzin,
  dataStatistikIzinCuti,
  getStatistikIzinCuti,
  dataStatistikKeterlambatan,
  getStatistikKeterlambatan,
}) => {
  const [state, setState] = useState({
    pilih_waktu_list: [],
    pilih_waktu: new Date().getUTCFullYear(),
    pilih_periode: 'tahun',
    jumlah_karyawan: 0,
    jumlah_unit_kerja: 0,
    jumlah_izin_cuti: 0,
    jumlah_telat: 0,
    jumlah_cuti: 0,
    data_statistik_izin_cuti: { label: [], data: [] },
    data_statistik_keterlambatan: { label: [], data: [] },
    page: 0,
    rowsPerPage: 10,
  });

  const getData = () => {
    var params;
    if (state.pilih_periode == 'bulan') {
      setState((prev) => ({
        ...prev,
        pilih_waktu_list: [
          {
            text: 'Januari',
            value: '1',
          },
          {
            text: 'Februari',
            value: '2',
          },
          {
            text: 'Maret',
            value: '3',
          },
          {
            text: 'April',
            value: '4',
          },
          {
            text: 'Mei',
            value: '5',
          },
          {
            text: 'Juni',
            value: '6',
          },
          {
            text: 'Juli',
            value: '7',
          },
          {
            text: 'Agustus',
            value: '8',
          },
          {
            text: 'September',
            value: '9',
          },
          {
            text: 'Oktober',
            value: '10',
          },
          {
            text: 'November',
            value: '11',
          },
          {
            text: 'Desember',
            value: '12',
          },
        ],
      }));
      params = `?periode=${state.pilih_periode}&${state.pilih_periode}=${state.pilih_waktu}`;
    } else {
      var year = new Date().getUTCFullYear();
      var yearList = [];

      for (let i = 0; i < 10; i++) {
        yearList.push(year - i);
      }

      yearList = yearList.map((year) => ({
        text: year,
        value: year,
      }));

      setState((prev) => ({
        ...prev,
        pilih_waktu_list: yearList,
      }));
      params = `?periode=${state.pilih_periode}&${state.pilih_periode}=${state.pilih_waktu}`;
    }
    getDataDashboardTop(params);
    getDataDashboardPermintaanIzin(params);
    getStatistikIzinCuti(params);
    getStatistikKeterlambatan(params);
  };

  useLayoutEffect(() => {
    getData();
  }, [state.pilih_waktu, state.pilih_periode]);

  useEffect(() => {
    var dataGraphIzinCuti;
    var dataGraphKeterlambatan;

    if (state.pilih_periode == 'tahun') {
      dataGraphIzinCuti = {
        label: [
          'Januari',
          'Februari',
          'Maret',
          'April',
          'Mei',
          'Juni',
          'Juli',
          'Agustus',
          'September',
          'Oktober',
          'November',
          'Desember',
        ],
        data: dataStatistikIzinCuti,
      };
      dataGraphKeterlambatan = {
        label: [
          'Januari',
          'Februari',
          'Maret',
          'April',
          'Mei',
          'Juni',
          'Juli',
          'Agustus',
          'September',
          'Oktober',
          'November',
          'Desember',
        ],
        data: dataStatistikKeterlambatan,
      };
    } else {
      const getDaysInMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
      };

      const currentYear = new Date().getFullYear();

      const totalDay = getDaysInMonth(currentYear, state.pilih_waktu);

      let arrDate = [];

      for (let i = 1; i <= totalDay; i++) {
        arrDate.push(i);
      }
      dataGraphIzinCuti = {
        label: arrDate,
        data: dataStatistikIzinCuti,
      };
      dataGraphKeterlambatan = {
        label: arrDate,
        data: dataStatistikKeterlambatan,
      };
    }

    setState((prev) => ({
      ...prev,
      jumlah_karyawan: dataDashboardTop.total_karyawan,
      jumlah_unit_kerja: dataDashboardTop.jumlah_unit_kerja,
      jumlah_izin_cuti: dataDashboardTop.izinCuti,
      jumlah_telat: dataDashboardTop.telat,
      data_statistik_izin_cuti: dataGraphIzinCuti,
      data_statistik_keterlambatan: dataGraphKeterlambatan,
    }));
  }, [
    dataDashboardTop,
    dataDashboardPermintaanIzin,
    dataStatistikIzinCuti,
    dataStatistikKeterlambatan,
  ]);

  const setPage = (page) => {
    setState({
      ...state,
      page,
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const setRowsPerPage = (event) => {
    setState({
      ...state,
      rowsPerPage: event.target.value,
    });
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-8 px-2"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm>
          <h1 className="font-semibold text-25 my-auto text-semiblack">
            Dashboard
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex items-center mr-4 justify-end gap-10"
        >
          <DashboardSelect
            dataSelect={[
              {
                text: 'Tahun',
                value: 'tahun',
              },
              {
                text: 'Bulan',
                value: 'bulan',
              },
            ]}
            label="Pilih Periode"
            state={state}
            setState={setState}
            name="pilih_periode"
          />
          <DashboardSelect
            dataSelect={state.pilih_waktu_list}
            label={
              state.pilih_periode == 'tahun' ? 'Pilih Tahun' : 'Pilih Bulan'
            }
            state={state}
            setState={setState}
            name="pilih_waktu"
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        className="d-flex align-items-center mt-3 mb-8"
      >
        <Grid item xs={12} sm className="rounded-lg pl-9 mx-4 bg-white h-134">
          <p className="font-semibold text-18 text-semiblack">Total Karyawan</p>
          <div className="d-flex items-center gap-16 h-42">
            <PeopleAltOutlinedIcon
              className="w-25 h-25 ml-6 text-primary"
              style={{ transform: 'scale(1.5)' }}
            />
            <span className="font-bold text-28 text-semiblack">
              {state.jumlah_karyawan}
            </span>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="rounded-lg pl-9 mx-4 bg-white"
          style={{
            height: '134px',
          }}
        >
          <p className="font-semibold text-18 text-semiblack">
            Jumlah Unit Kerja
          </p>
          <div className="d-flex items-center gap-16 h-42">
            <WorkOutlineOutlinedIcon
              className="w-25 h-25 ml-6 text-primary"
              style={{ transform: 'scale(1.5)' }}
            />
            <span className="font-bold text-28 text-semiblack">
              {state.jumlah_unit_kerja}
            </span>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="rounded-lg pl-9 mx-4 bg-white"
          style={{
            height: '134px',
          }}
        >
          <p className="font-semibold text-18 text-semiblack">Izin & Cuti</p>
          <div className="d-flex items-center gap-16 h-42">
            <ExitToAppOutlinedIcon
              className="w-25 h-25 ml-6 text-primary"
              style={{ transform: 'scale(1.5)' }}
            />
            <span className="font-bold text-28 text-semiblack">
              {state.jumlah_izin_cuti}
            </span>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="rounded-lg pl-9 mx-4 bg-white"
          style={{
            height: '134px',
          }}
        >
          <p className="font-semibold text-18 text-semiblack">Telat</p>
          <div className="d-flex items-center gap-16 h-42">
            <ScheduleOutlinedIcon
              className="w-25 h-25 ml-6 text-primary"
              style={{ transform: 'scale(1.5)' }}
            />
            <span className="font-bold text-28 text-semiblack">
              {state.jumlah_telat}
            </span>
          </div>
        </Grid>
      </Grid>
      <div className="mt-16">
        <SimpleCard>
          <div className="mx-3">
            <h1 className="font-semibold mt-4 mb-10 text-semiblack">
              Permintaan Izin Terbaru
            </h1>
            <div className="w-full overflow-auto bg-white">
              <Table className="buku-kas-table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={1} align="center">
                      <span className="text-15">No</span>
                    </TableCell>
                    <TableCell colSpan={3} className="pl-3">
                      <span className="text-15">Nama</span>
                    </TableCell>
                    <TableCell colSpan={3} className="" align="center">
                      <span className="text-15">Unit Kerja</span>
                    </TableCell>
                    <TableCell colSpan={4} className="" align="center">
                      <span className="text-15">Tanggal</span>
                    </TableCell>
                    <TableCell colSpan={3} className="" align="center">
                      <span className="text-15">Status</span>
                    </TableCell>
                    <TableCell colSpan={2} className="" align="center">
                      <span className="text-15">Aksi</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <RenderTableDashboard
                    data={dataDashboardPermintaanIzin}
                    getData={getData}
                    state={state}
                  />
                </TableBody>
              </Table>

              <TablePagination
                className="px-16"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={
                  dataDashboardPermintaanIzin?.length
                    ? dataDashboardPermintaanIzin?.length
                    : 0
                }
                rowsPerPage={state.rowsPerPage}
                labelRowsPerPage={'From'}
                page={state.page}
                backIconButtonProps={{
                  'aria-label': 'Previous page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next page',
                }}
                backIconButtonText="Previous page"
                nextIconButtonText="Next page"
                onPageChange={handleChangePage}
                onRowsPerPageChange={setRowsPerPage}
              />
            </div>
          </div>
        </SimpleCard>

        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          className="mt-16"
        >
          <Grid item xs={12} sm={6}>
            <SimpleCard>
              <CardChartTotalPenghargaan
                label="Jumlah Izin & Cuti"
                chart={state.data_statistik_izin_cuti}
                loading={false}
                title="Statistik Izin & Cuti"
              />
            </SimpleCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SimpleCard>
              <CardChartTotalPenghargaan
                label="Jumlah Terlambat"
                chart={state.data_statistik_keterlambatan}
                loading={false}
                title="Statistik Keterlambatan"
              />
            </SimpleCard>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataDashboardTop: state.dashboard.dataDashboardTop,
    dataDashboardPermintaanIzin: state.dashboard.dataDashboardPermintaanIzin,
    dataStatistikIzinCuti: state.dashboard.dataStatistikIzinCuti,
    dataStatistikKeterlambatan: state.dashboard.dataStatistikKeterlambatan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDataDashboardTop: (params) => dispatch(getDataDashboardTop(params)),
    getDataDashboardPermintaanIzin: (params) =>
      dispatch(getDataDashboardPermintaanIzin(params)),
    getStatistikIzinCuti: (params) => dispatch(getStatistikIzinCuti(params)),
    getStatistikKeterlambatan: (params) =>
      dispatch(getStatistikKeterlambatan(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
