import {
  Button,
  ButtonGroup,
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { SimpleCard } from "matx";
import React, {
  Component,
  Fragment,
  useEffect,
  useState,
  useLayoutEffect,
} from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { formatTanggal } from "app/utils/globalFunction";
import { getDenda, delDenda } from "app/redux/actions/gaji/DendaAction";
import AbsensiForm from "../absensi/AbsensiForm";
import { Link } from "react-router-dom";
import { RenderTable } from "app/components";
import DatePickerComponent from "../../components/select/DatePickerComponent";
import { SelectText } from "app/components";
import AddIcon from "@material-ui/icons/Add";

const Denda = ({ dataDenda, getDenda, delDenda }) => {
  const [state, setState] = useState({
    loading: true,
    search: "",
    searchTgl: 0,
    page: 0,
    rowsPerPage: 10,
    selectedItem: "",
    dataLanguage: [
      {
        label: "Indonesia",
        value: "ind",
      },
      {
        label: "English",
        value: "eng",
      },
    ],
    bahasa: "",
  });

  const formattedCurrentDate = (Date) => {
    let year = Date.getFullYear();
    let month = Date.getMonth() + 1;
    let date = Date.getDate();
    let dateFormat = `${year}-${month.toString().padStart(2, "0")}-${date
      .toString()
      .padStart(2, "0")}`;

    return dateFormat;
  };
  const [search, setSearch] = useState("");
  const [searchTgl, setSearchTgl] = useState(formattedCurrentDate(new Date()));
  const [jenisDenda, setJenisDenda] = useState("");

  const setPage = (page) => {
    setState({ ...state, page });
  };

  const setRowsPerPage = (event) => {
    setState({ ...state, rowsPerPage: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getData = () => {
    var dateFormat = JSON.stringify(searchTgl);
    dateFormat = dateFormat.slice(1, 8);
    dateFormat = dateFormat.split("-").reverse().join("-");

    let params = `?bulan_tahun=${dateFormat}`;
    if (search !== "") {
      params += `&search=${search}`;
    }

    getDenda(params);
  };

  const submitSearch = (e) => {
    if (e.keyCode == 13) {
      getData();
    }
  };
  const handleDateChange = (date) => {
    setSearchTgl(date);
  };

  useLayoutEffect(() => {
    getData();
  }, [searchTgl, jenisDenda]);

  useLayoutEffect(() => {
    getData();
  }, []);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Hapus",
      text: "Apakah kamu yakin ?",
      showCancelButton: true,
      confirmButtonText: "Yakin",
      cancelButtonText: "Batal",
      icon: "warning",
    }).then((res) => {
      if (res.isConfirmed) {
        delDenda(id)
          .then((res) => {
            if (res.code === 0) {
              Swal.fire({
                title: "Berhasil",
                text: "Data berhasil dihapus",
                timer: 2000,
                icon: "success",
              });
              getDenda();
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "gagal",
              text: "Data Gagal dihapus",
              timer: 2000,
              icon: "error",
            });
          });
      }
    });
  };

  const handleChange = (e) => {
    setState({
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="m-sm-30">
      <Grid container spacing={1} justify="space-between" className="my-4 mb-8">
        <h1 className="mt-4 font-semibold text-25 ">Denda</h1>
        <div className="tambah-button">
          <Link to={`/Denda/Tambah`}>
            <Button>
              <AddIcon />
              <div style={{ marginLeft: "5px" }}>Tambah</div>
            </Button>
          </Link>
        </div>
      </Grid>
      <SimpleCard loading={false} currency="" saldo="">
        <Fragment>
          <div
            className="d-flex items-center py-4 "
            style={{ justifyContent: "flex-end", gap: "20px" }}
          >
            <TextField
              size="small"
              variant="outlined"
              className="karyawan-top-search"
              placeholder="Cari Nama Karyawan"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              onKeyDown={submitSearch}
              name="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
              }}
            />
            <div style={{ width: "170px" }}>
              <DatePickerComponent
                date={searchTgl}
                handleDate={handleDateChange}
                name="tanggal"
                tipe="MMMM yyyy"
                search
              />
            </div>

            {/* <Grid item xs={4} sm={2} className="d-flex align-items-end">
              <SelectText
                jenisDenda
                search
                label="Jenis Denda"
                value={jenisDenda}
                handleChange={(e) => setJenisDenda(e.target.value)}
              />
            </Grid> */}
          </div>
          <div className="w-full overflow-auto bg-white">
            <Table
              className="buku-kas-table"
              style={{
                borderTop: "1px #e6e5e5 solid",
                marginTop: "20px",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>No</TableCell>
                  <TableCell colSpan={2}>Nama</TableCell>
                  <TableCell align="center" colSpan={4}>
                    Nominal
                  </TableCell>
            
                  <TableCell align="center" colSpan={3}>
                    Aksi
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <RenderTable
                  state={state}
                  data={dataDenda}
                  tableName="denda"
                  handleDelete={handleDelete}
                  dataKosongSpan={11}
                />
              </TableBody>
            </Table>
            <TablePagination
              className="px-16"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataDenda?.length ? dataDenda?.length : 0}
              rowsPerPage={state.rowsPerPage}
              labelRowsPerPage={"From"}
              page={state.page}
              backIconButtonProps={{
                "aria-label": "Previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next page",
              }}
              backIconButtonText="Previous page"
              nextIconButtonText="Next page"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={setRowsPerPage}
            />
          </div>
        </Fragment>
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataDenda: state.gaji.dataDenda,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDenda: (params) => dispatch(getDenda(params)),
    delDenda: (params) => dispatch(delDenda(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Denda);
