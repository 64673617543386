import { useState } from 'react';
import { Dialog, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function ModalTextAbsensi({
  terlambat,
  state,
  setState,
  item,
  editKaryawanAbsensi,
  disableJamMasuk,
  disableJamPulang,
  getData,
}) {
  const [open, setOpen] = useState(false);
  const [jamMasukChange, setJamMasukChange] = useState(false);
  const history = useHistory();
  const useStyles = makeStyles({
    dialog: {
      width: '460px',
    },
    backDrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  });
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (item.hasOwnProperty('id')) {
      getData(item.id);
    } else {
      getData();
    }
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeJamMasuk = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });

    setJamMasukChange(true);
  };

  const handleSubmit = async () => {
    if (terlambat) {
      try {
        let dateFormat = JSON.stringify(
          item.hasOwnProperty('tanggal') ? item.tanggal : state.searchTgl
        );
        dateFormat = dateFormat.slice(1, 11);

        await editKaryawanAbsensi({
          users_code: item.users_code == undefined ? item.id : item.users_code,
          jam_masuk: jamMasukChange ? state.jam_masuk : item.jam_masuk,
          tanggal: dateFormat,
          status: 'hadir',
          absensi_karyawan_code: item.absensi_karyawan_code,
          catatan: state.catatan == '' ? 'tidak ada catatan' : state.catatan,
        });

        setState({
          jam_masuk: '00:00',
          jam_pulang: '00:00',
          catatan: '',
        });

        handleClose();

        Swal.fire('Success!', 'Data Absensi berhasil disimpan', 'success');
        setJamMasukChange(false);
      } catch (e) {
        Swal.fire('Oopss!', `Data Absensi gagal disimpan ${e}`, 'error');
        setJamMasukChange(false);
      }
    } else {
      try {
        let dateFormat = JSON.stringify(
          item.hasOwnProperty('tanggal') ? item.tanggal : state.searchTgl
        );
        dateFormat = dateFormat.slice(1, 11);

        await editKaryawanAbsensi({
          users_code: item.users_code == undefined ? item.id : item.users_code,
          jam_masuk: '',
          jam_pulang: '',
          tanggal: dateFormat,
          status: 'tidak hadir',
          absensi_karyawan_code: item.absensi_karyawan_code,
          catatan: state.catatan == '' ? 'tidak ada catatan' : state.catatan,
        });

        setState({
          jam_masuk: '00:00',
          jam_pulang: '00:00',
          catatan: '',
        });

        handleClose();

        Swal.fire('Success!', 'Data Absensi berhasil disimpan', 'success');
        setJamMasukChange(false);
      } catch (e) {
        Swal.fire('Oopss!', `Data Absensi gagal disimpan ${e}`, 'error');
        setJamMasukChange(false);
      }
    }
  };

  return (
    <div className="m-0 p-0">
      {terlambat ? (
        <span
          onClick={() => handleClickOpen(true)}
          className="font-medium text-14  text-yellow"
        >
          Terlambat
        </span>
      ) : (
        <span
          onClick={() => handleClickOpen(false)}
          className="font-medium text-14  text-red"
        >
          Tidak Hadir
        </span>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.dialog,
        }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        style={{ overflow: 'hidden !important', margin: 0 }}
      >
        <div className="my-8 px-8">
          <h1 className="text-18 font-bold text-black mb-6">Atur Kehadiran</h1>
          <p
            className="text-14 font-medium"
            style={{ color: '#BBBBBB', marginBottom: '-7px' }}
          >
            Nama Karyawan
          </p>
          <p className="text-16 font-medium text-black mb-6">{item.name}</p>
          <p className="text-14 font-medium" style={{ color: '#BBBBBB' }}>
            Kehadiran
          </p>
          {terlambat ? (
            <div className="d-flex">
              <Button
                variant="contained"
                className="bg-primary text-white elevation-z0 p-4 mr-3"
                style={{ width: '120px' }}
              >
                Hadir
              </Button>
              <Button
                variant="contained"
                className="text-white elevation-z0 p-4 ml-3"
                style={{ background: '#CECECE', width: '120px' }}
              >
                Tidak Hadir
              </Button>
            </div>
          ) : (
            <div className="d-flex">
              <Button
                variant="contained"
                className="text-white elevation-z0 p-4 mr-3"
                style={{ background: '#CECECE', width: '120px' }}
              >
                Hadir
              </Button>
              <Button
                variant="contained"
                className="bg-error text-white elevation-z0 p-4 ml-3"
                style={{ width: '120px' }}
              >
                Tidak Hadir
              </Button>
            </div>
          )}
          {terlambat ? (
            <div className="d-flex" style={{ marginTop: '8.5px' }}>
              <div className="mr-3 w-full">
                <p className="text-14 font-medium" style={{ color: '#BBBBBB' }}>
                  Jam Masuk
                </p>
                <TextField
                  name="jam_masuk"
                  className="w-full"
                  label="Jam Masuk"
                  variant="outlined"
                  type="time"
                  disabled={disableJamMasuk}
                  value={jamMasukChange ? state.jam_masuk : item.jam_masuk}
                  onChange={handleChangeJamMasuk}
                  style={{ scaleY: '0.8' }}
                />
              </div>
              <div className="ml-3 w-full">
                <p className="text-14 font-medium" style={{ color: '#BBBBBB' }}>
                  Jam Pulang
                </p>
                <TextField
                  name="jam_pulang"
                  className="w-full"
                  label="Jam Pulang"
                  variant="outlined"
                  type="time"
                  disabled={disableJamPulang}
                  value={state.jam_pulang}
                  onChange={handleChange}
                  style={{ scaleY: '0.8' }}
                />
              </div>
            </div>
          ) : (
            <div style={{ marginTop: '25px' }}>
              <p className="text-14 font-medium" style={{ color: '#BBBBBB' }}>
                Catatan
              </p>
              <textarea
                name="catatan"
                rows="4"
                cols="50"
                className="w-full text-14 font-normal rounded-lg px-4 py-3"
                placeholder="Catatan (Tidak Wajib)"
                onChange={handleChange}
                style={{
                  backgroundColor: '#fbfbfb',
                  border: '1px solid #bcbfc2',
                  borderRadius: '5px',
                }}
              />
            </div>
          )}
          <div className="d-flex" style={{ marginTop: '35px' }}>
            <Button
              variant="outlined"
              className="w-full text-primary bg-transparent elevation-z0 mr-3 p-4"
              onClick={handleClose}
            >
              Batal
            </Button>
            <Button
              variant="contained"
              className="w-full bg-primary text-white elevation-z0 ml-3 p-4"
              onClick={() => handleSubmit()}
            >
              Simpan
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
