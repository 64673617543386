import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import { dateFormatter } from 'globalize';

function IzinCutiDatePicker({
  state,
  setState,
  name,
  tipe = 'dd/MM/yyyy',
  format = 'short',
  slice = 11,
  size = 'small',
  paddingBlock = '',
  scaleY = '1',
}) {
  const DatePicker = withStyles({
    root: {
      borderRadius: '5px',
      background: '#fbfbfb',
      width: format === 'long' ? '100%' : '185px',
      paddingBlock: paddingBlock,

      '& .MuiInputBase-root': {
        paddingLeft: 5,
        paddingRight: 5,
      },
    },
  })((props) => <KeyboardDatePicker color="default" {...props} />);
  const handleTanggal = (e) => {
    const event = new Date(e);

    let dateFormat = JSON.stringify(event);

    dateFormat = dateFormat.slice(1, slice);
    console.log(dateFormat);
    setState({
      ...state,
      [name]: dateFormat,
    });
    console.log(state);
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          format={tipe}
          control={<KeyboardDatePicker />}
          value={state[name]}
          InputAdornmentProps={{
            position: 'end',
          }}
          onChange={handleTanggal}
          size={size}
          style={{
            transform: `scaleY(${scaleY})`,
          }}
          title="Ganti untuk merubah tanggal yang diperbolehkan"
        />
      </MuiPickersUtilsProvider>
    </>
  );
}

export default IzinCutiDatePicker;
