import {
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { SimpleCard } from "matx";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getDataAbsen,
  addKaryawanAbsensi,
  editKaryawanAbsensi,
} from "app/redux/actions/AbsenAction";
import Aksieye from "../../../assets/component/Aksieye.svg";
import AbsensiDatePicker from "./component/AbsensiDatePicker";
import ModalAbsensiHadir from "./component/modal/ModalAbsensiHadir";
import ModalTextAbsensi from "./component/modal/ModalTextAbsensi";
import { Link } from "react-router-dom";
import ModalFilterAbsensiKaryawan from "./component/modal/ModalFilterAbsensiKaryawan";

class Absensi extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      search_nama: "", // filter berdasarkan nama
      search_type: "", //intern / fulltime
      search_status: "", //izin, cuti / terlambat
      searchTgl: new Date(),
      page: 0,
      rowsPerPage: 10,
      jam_masuk: "00:00",
      jam_pulang: "00:00",
      catatan: "",
    };
    this.getData.bind(this);
    this.setState.bind(this);
  }

  setPage = (page) => {
    this.setState({ page });
  };

  setRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  getData = () => {
    const { getDataAbsen } = this.props;
    let dateFormat = JSON.stringify(this.state.searchTgl).slice(1, 11);

    var params = `?tanggal=${dateFormat}&search_nama=${this.state.search_nama}&search_type=${this.state.search_type}&search_status=${this.state.search_status}`;

    getDataAbsen(params);
  };

  componentDidMount = () => {
    this.getData();
  };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevState.searchTgl !== this.state.searchTgl) {
      return this.state.searchTgl;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      const { getDataAbsen } = this.props;
      let dateFormat = JSON.stringify(this.state.searchTgl);
      dateFormat = dateFormat.slice(1, 11);

      const params = `?tanggal=${dateFormat}`;
      getDataAbsen(params);
    }
  }

  renderTable2 = () => {
    const { page, rowsPerPage } = this.state;
    const { dataAbsen, addKaryawanAbsensi, editKaryawanAbsensi } = this.props;

    const handleNumbering = () => {
      if (rowsPerPage === 5) {
        return page * 5;
      } else if (rowsPerPage === 10) {
        return page * 10;
      } else if (rowsPerPage === 25) {
        return page * 25;
      }
    };
    return dataAbsen?.length > 0 ? (
      dataAbsen
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => {
          return (
            <TableRow hover key={index}>
              <TableCell
                className="font-normal text-14  text-body text-semiblack"
                colSpan={1}
              >
                {index + 1 + handleNumbering()}
              </TableCell>
              <TableCell
                className="font-normal text-14  text-body text-semiblack"
                colSpan={3}
              >
                {item.name}
              </TableCell>
              <TableCell
                align="center"
                className="font-normal text-14  text-body text-semiblack"
                colSpan={3}
              >
                {item.unit_kerja}
              </TableCell>
              <TableCell align="center" colSpan={4}>
                {item.status === "" ? (
                  <div className="button-container-kehadiran">
                    <ModalAbsensiHadir
                      hadir={false}
                      state={this.state}
                      setState={this.setState.bind(this)}
                      name="jam_pulang"
                      item={item}
                      addKaryawanAbsensi={addKaryawanAbsensi}
                      getData={this.getData.bind(this)}
                    />
                    <ModalAbsensiHadir
                      hadir={true}
                      state={this.state}
                      setState={this.setState.bind(this)}
                      name="jam_masuk"
                      item={item}
                      addKaryawanAbsensi={addKaryawanAbsensi}
                      disableJamMasuk={false}
                      disableJamPulang={true}
                      getData={this.getData.bind(this)}
                    />
                  </div>
                ) : item?.status?.toLowerCase() === "hadir" ? (
                  <span className="font-medium text-primary text-14 ">
                    Hadir
                  </span>
                ) : item?.status?.toLowerCase() === "cuti" ? (
                  <span className="font-medium text-semiblack text-14 ">
                    Cuti
                  </span>
                ) : item?.status?.toLowerCase() === "izin" ? (
                  <span className="font-medium text-semiblack text-14 ">
                    Izin
                  </span>
                ) : item?.status?.toLowerCase() === "weekend" ? (
                  <span className="font-medium text-danger text-14 ">
                    Weekend
                  </span>
                ) : item?.status?.toLowerCase() === "terlambat" ? (
                  <ModalTextAbsensi
                    terlambat={true}
                    state={this.state}
                    setState={this.setState.bind(this)}
                    name="jam_masuk"
                    item={item}
                    editKaryawanAbsensi={editKaryawanAbsensi}
                    disableJamMasuk={false}
                    disableJamPulang={true}
                    getData={this.getData.bind(this)}
                  />
                ) : (
                  <ModalTextAbsensi
                    terlambat={false}
                    state={this.state}
                    setState={this.setState.bind(this)}
                    name="jam_masuk"
                    item={item}
                    editKaryawanAbsensi={editKaryawanAbsensi}
                    disableJamMasuk={false}
                    disableJamPulang={true}
                    getData={this.getData.bind(this)}
                  />
                )}
              </TableCell>
              <TableCell
                align="center"
                className="font-medium text-14  text-semiblack"
                colSpan={3}
              >
                {item?.jam_masuk === "" ? "-" : item?.jam_masuk.slice(0, 5)}
              </TableCell>
              <TableCell
                align="center"
                className="font-medium text-14  text-semiblack"
                colSpan={3}
              >
                {item?.status.toLowerCase() == "tidak hadir" ? (
                  "-"
                ) : item?.pulang === "" ? (
                  "-"
                ) : item?.jam_pulang === "" ? (
                  <ModalAbsensiHadir
                    hadir={true}
                    state={this.state}
                    setState={this.setState.bind(this)}
                    name="jam_pulang"
                    item={item}
                    editKaryawanAbsensi={editKaryawanAbsensi}
                    disableJamMasuk={true}
                    disableJamPulang={false}
                    getData={this.getData.bind(this)}
                  />
                ) : (
                  item?.jam_pulang.slice(0, 5)
                )}
              </TableCell>
              <TableCell
                align="center"
                className="font-medium text-14  text-semiblack"
                colSpan={1}
              >
                <Link to={`/absensi/${item?.users_code}`}>
                  <div className="dot-container">
                    <img src={Aksieye} alt="eye" />
                  </div>
                </Link>
              </TableCell>
            </TableRow>
          );
        })
    ) : (
      <TableRow hover>
        <TableCell
          className="font-medium text-14 text-semiblack"
          colSpan={18}
          align="center"
        >
          Data kosong
        </TableCell>
      </TableRow>
    );
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSearch = (e) => {
    if (e.keyCode == 13) {
      this.getData();
    }
  };

  render() {
    const { page, rowsPerPage } = this.state;
    const { dataAbsen } = this.props;
    return (
      <div className="m-sm-30">
        <h1 className="font-semibold text-25 my-8 text-semiblack">
          Absensi Karyawan
        </h1>
        <SimpleCard>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item lg={6} sm={12}>
              <div
                className="flex items-start pb-2"
                style={{
                  justifyContent: "flex-end",
                }}
              >
                {/* <TextField
                  size="small"
                  style={{
                    width: '270px',
                    height: '46px',
                    background: '#ffffff',
                  }}
                  variant="outlined"
                  className="pr-5 rounded-lg"
                  placeholder="Cari Nama Karyawan"
                  onChange={this.handleChange}
                  onKeyDown={this.handleSearch}
                  name="search_nama"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>search_nama</Icon>
                      </InputAdornment>
                    ),
                  }}
                /> */}
                <ModalFilterAbsensiKaryawan
                  state={this.state}
                  setState={this.setState.bind(this)}
                  getData={this.getData.bind(this)}
                />
                <AbsensiDatePicker
                  state={this.state}
                  setState={this.setState.bind(this)}
                  name="searchTgl"
                  tipe="dd MMMM yyyy"
                />
              </div>
            </Grid>
          </Grid>
          <div className="w-full overflow-auto bg-white">
            <Table
              className="buku-kas-table"
              style={{
                borderTop: "1px #e6e5e5 solid",
                marginTop: "20px",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={1}
                    className="py-5 font-medium text-15 text-semiblack"
                  >
                    No
                  </TableCell>

                  <TableCell
                    colSpan={3}
                    className="py-5 font-medium text-15 text-semiblack"
                  >
                    Nama
                  </TableCell>

                  <TableCell
                    align="center"
                    colSpan={3}
                    className="py-5 font-medium text-15 text-semiblack"
                  >
                    Unit Kerja
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={4}
                    className="py-5 font-medium text-15 text-semiblack"
                  >
                    Status
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={3}
                    className="py-5 font-medium text-15 text-semiblack"
                  >
                    Jam Masuk
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={3}
                    className="py-5 font-medium text-15 text-semiblack"
                  >
                    Jam Pulang
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={1}
                    className="py-5 font-medium text-15 text-semiblack"
                  >
                    Aksi
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{this.renderTable2()}</TableBody>
            </Table>
            <TablePagination
              className="px-16 mt-7"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataAbsen?.length ? dataAbsen?.length : 0}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={"From"}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next page",
              }}
              backIconButtonText="Previous page"
              nextIconButtonText="Next page"
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.setRowsPerPage}
            />
          </div>
        </SimpleCard>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataAbsen: state.absen.dataAbsen,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDataAbsen: (params) => dispatch(getDataAbsen(params)),
    addKaryawanAbsensi: (body) => dispatch(addKaryawanAbsensi(body)),
    editKaryawanAbsensi: (body) => dispatch(editKaryawanAbsensi(body)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Absensi);
