import { Button, ButtonGroup, Grid, Icon } from "@material-ui/core";
import { SimpleCard } from "matx";
import React, { Component, Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getMOM, delMOM, downloadMOM } from "app/redux/actions/MOMActions";
import Swal from "sweetalert2";
import { Link, useParams, useHistory } from "react-router-dom";
import {
     editBonus,
  getDetailBonus,
} from "app/redux/actions/gaji/BonusAction";

const DetailBonus = ({
  getDetailBonus,
  detailBonus
}) => {
 

  const { id } = useParams();
  const [foundData, setFoundData] = useState({
    name: "",
    tanggal: "",
    keterangan: "",
    nama_denda: "",
    nominal: "",
    jenis_bonus: "",
    unit_kerja: "",
  });
  const getData = () => {
    getDetailBonus(id);
  };

  useEffect(() => {
    getData();
    
  }, []);


  useEffect(() => {
    setTimeout(() => {}, 1000);
    setFoundData({
      name: detailBonus?.name,
      tanggal: detailBonus?.tanggal,
      keterangan: detailBonus?.keterangan,
      nominal: detailBonus?.nominal,
      jenis_bonus: detailBonus?.jenis,
      unit_kerja: detailBonus?.unit_kerja,

      

    });
  }, [detailBonus]);



   

  return (
    <div className="m-sm-30">
      <h1 className="mb-20px">Detail Bonus</h1>
      <SimpleCard loading={false} currency="" saldo="">
        <Fragment>
          <div className="simple-card-content">
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <h5>Nama Karyawan</h5>
                <div>{foundData.name}</div>
              </Grid>
               <Grid item sm={6} xs={12}>
                <h5>Unit Kerja</h5>
                <div>{foundData.unit_kerja}</div>
              </Grid>
              <Grid item sm={6} xs={12} className="mb-20px">
                <h5>Jenis Bonus</h5>
                <div>{foundData.jenis_bonus}</div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <h5>Nominal</h5>
                <div>{foundData.nominal}</div>
              </Grid>
              <Grid item sm={12} xs={24} className="mb-20px">
                <h5>Tanggal</h5>
                <div>{foundData.tanggal}</div>
              </Grid>

              

              
              <Grid item sm={6} xs={12}>
                <h5>Keterangan</h5>
                <div>{foundData.keterangan}</div>
              </Grid>
            </Grid>
          </div>
        </Fragment>
      </SimpleCard>
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    detailBonus: state.gaji.detailBonus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDetailBonus: (code) => dispatch(getDetailBonus(code)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(DetailBonus);
