import React, { useState } from "react";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Button,
  Avatar,
  Chip,
} from "@material-ui/core";
import foto_profile from "assets/page/karyawan/foto_profile.png";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { formatRupiah } from "app/utils/globalFunction";

import Aksieye from "assets/component/Aksieye.svg";
import SimpleMenu from "app/components/menu/Menu";

const RenderTableGaji = ({
  data,
  state,
  tableName,
  handleDelete,
  handleDetail,
  handleEdit,
  handleUnduh,
  aksiClick,
  clickEdit,
  clickDetail,
}) => {
  const handleNumbering = () => {
    if (state.rowsPerPage === 5) {
      return state.page * 5;
    } else if (state.rowsPerPage === 10) {
      return state.page * 10;
    } else if (state.rowsPerPage === 25) {
      return state.page * 25;
    }
  };

  const timeWithoutSeconds = (timeString) => {
    if (timeString) {
      return timeString.substring(0, 5);
    } else {
      return "loading...";
    }
  }; // "08:00"

  function formatDate(dateString) {
    if (dateString) {
      const parts = dateString?.split("-");
      return `${parts[0]}/${parts[1]}/${parts[2]}`;
    }
    return '-';
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "28px",
      color: "#FFFFFF",
      flex: "none",
      width: "110px",
      height: "36px",
    },
    yellow: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "28px",
      color: "#FFFFFF",
      flex: "none",
      width: "110px",
      height: "36px",
      background: "#FBC02A",
    },
    red: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "28px",
      color: "#FFFFFF",
      flex: "none",
      width: "110px",
      height: "36px",
      background: "#FF5C58",
    },
  }));

  const classes = useStyles();

  return data?.length > 0 ? (
    data
      .slice(
        state.page * state.rowsPerPage,
        state.page * state.rowsPerPage + state.rowsPerPage
      )
      .map((item, index) => (
        <TableRow hover key={index}>
          <TableCell colSpan={1}>{index + 1}</TableCell>
          <TableCell colSpan={1}>
            <div
              className=" z-100 text-14 d-flex items-center"
              style={{ gap: "16px" }}
            >
              {item.foto_profile === "" ? (
                <Avatar
                  src={foto_profile}
                  variant="square"
                  alt="foto profil"
                  style={{
                    width: "50px",
                    height: "50px",
                    background: "white",
                  }}
                />
              ) : (
                <Avatar
                  variant="square"
                  src={item.foto_profile}
                  width={"50px"}
                />
              )}

              {item?.name}
            </div>
          </TableCell>
          <TableCell align="center" colSpan={3}>
            {formatDate(item?.periode_awal)}-{formatDate(item?.periode_akhir)}
          </TableCell>

          <TableCell align="center" colSpan={2}>
            {formatRupiah(item?.total_gaji)}
          </TableCell>
          <TableCell align="center" colSpan={2}>
            {item?.status === "belum lunas" ? (
              <div
                className="tambah-button"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                onClick={() => handleEdit(item?.gaji_code)}
              >
                <Button>Bayar Sekarang</Button>
              </div>
            ) : (
              item?.status
            )}
          </TableCell>
          <TableCell align="center" colSpan={1}>
            <Button className="karyawan-aksi-dot elevation-z0">
              <SimpleMenu
                item={item}
                linkDetail={`/RiwayatGaji/Detail/${item.gaji_code}`}
                noDelete
                unduhSlipGaji
                handleUnduh={() => handleUnduh(item?.gaji_code)}
              />
            </Button>
          </TableCell>
        </TableRow>
      ))
  ) : (
    <TableRow hover>
      <TableCell colSpan={10} align="center">
        Data kosong
      </TableCell>
    </TableRow>
  );
};

export default RenderTableGaji;
