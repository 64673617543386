import React, { useState, useEffect, useLayoutEffect } from "react";
import { SimpleCard } from "matx";
import { Link } from "react-router-dom";
import {
  Button,
  TextField,
  Icon,
  InputAdornment,
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Grid,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import DatePickerComponent from "../../components/select/DatePickerComponent";
import TableMagangPernyataan from "./component/TableMagangPernyataan";

import Swal from "sweetalert2";

import {
  getPernyataanMagang,
  delPernyataanMagang,
} from "app/redux/actions/administrasi/MagangAction";
import { connect } from "react-redux";

const MagangPernyataan = ({
  dataAdministrasi,
  getPernyataanMagang,
  delPernyataanMagang,
}) => {
  const [search, setSearch] = useState("");
  const [searchTgl, setSearchTgl] = useState(new Date());
  const [pengajuan, setPengajuan] = useState(true);
  const [open, setOpen] = useState(false);

  const getData = () => {
    var dateFormat = JSON.stringify(searchTgl);
    dateFormat = dateFormat.slice(1, 8);
    dateFormat = dateFormat.split("-").reverse().join("-");
    dateFormat = `?bulan_tahun=${dateFormat}`;

    let params =
      search === ""
        ? dateFormat
        : `?search=${search}&${dateFormat.slice(1, 20)}`;

    getPernyataanMagang(params);
  };

  const submitSearch = (e) => {
    if (e.keyCode == 13) {
      getData();
    }
  };
  const handleDateChange = (date) => {
    setSearchTgl(date);
  };

  useLayoutEffect(() => {
    getData();
  }, [searchTgl]);

  useLayoutEffect(() => {
    getData();
  }, []);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Hapus",
      text: "Apakah kamu yakin ?",
      showCancelButton: true,
      confirmButtonText: "Yakin",
      cancelButtonText: "Batal",
      icon: "warning",
    }).then((res) => {
      if (res.isConfirmed) {
        delPernyataanMagang(id)
          .then((res) => {
            if (res.code === 0) {
              Swal.fire({
                title: "Berhasil",
                text: "Data berhasil dihapus",
                timer: 2000,
                icon: "success",
              });
              getPernyataanMagang();
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "gagal",
              text: "Data Gagal dihapus",
              timer: 2000,
              icon: "error",
            });
          });
      }
    });
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="mb-4 mx-auto px-2"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm>
          <h1
            className="font-semibold text-25 my-auto"
            style={{ color: "#0A0A0A" }}
          >
            Surat Pernyataan Magang
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          className="d-flex mr-8"
          style={{ justifyContent: "flex-end" }}
        >
          <Link to="/administrasi/magang/pernyataan/add">
            <Button
              variant="contained"
              color="primary"
              className="karyawan-btn-top px-8 py-3"
            >
              <AddIcon /> <span className="karyawan-btn-span">Tambah</span>
            </Button>
          </Link>
        </Grid>
      </Grid>
      <SimpleCard title="">
        <div
          className="d-flex items-center py-4 "
          style={{ justifyContent: "flex-end", gap: "20px" }}
        >
          <TextField
            size="small"
            variant="outlined"
            className="karyawan-top-search"
            placeholder="Cari Nama Karyawan"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            onKeyDown={submitSearch}
            name="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              ),
            }}
          />
          <Grid item xs={3} sm={2} className="d-flex align-items-end">
            <div>
              <DatePickerComponent
                date={searchTgl}
                handleDate={handleDateChange}
                name="tanggal"
                search
                tipe="MMMM yyyy"
              />
            </div>
          </Grid>
        </div>
        <TableMagangPernyataan
          data={dataAdministrasi}
          handleDelete={handleDelete}
        />
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataAdministrasi: state.administrasi.dataAdministrasi,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPernyataanMagang: (params) => dispatch(getPernyataanMagang(params)),
    delPernyataanMagang: (code) => dispatch(delPernyataanMagang(code)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MagangPernyataan);
