import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, Card } from "@material-ui/core";
import Swal from "sweetalert2";
import { RichTextEditor } from "matx";

import {
  getAturan,
  saveAturan,
} from "app/redux/actions/kebijakan/AturanActions";

const Aturan = () => {
  const dispatch = useDispatch();
  const { dataAturan } = useSelector((state) => state.kebijakanAturan);
  const [aturan, setAturan] = React.useState("");

  console.log(dataAturan);

  const getData = () => {
    dispatch(getAturan());
  };

  React.useLayoutEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    if (dataAturan !== []) {
      setAturan(dataAturan);
    }
  }, [dataAturan]);

  const handleChange = (e) => {
    setAturan(e);
  };

  const handleSubmit = () => {
    try {
      saveAturan({ aturan }).then((res) => {
        Swal.fire("Success!", "Data Aturan berhasil disimpan", "success");
        getData();
      });
    } catch (e) {
      Swal.fire("Oopss!", "Data Aturan gagal disimpan", "error");
    }
  };

  return (
    <div className="m-sm-30">
      <Grid
        container
        spacing={3}
        className="my-8 mx-auto px-2"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <h1 className="fw-600 text-25 text-black">Aturan</h1>
        </Grid>
        <Grid item xs={12} sm className="d-flex mr-4 justify-end">
          <Button
            variant="contained"
            color="primary"
            className="px-15 py-3 text-white fw-500 border-radius-4"
            onClick={handleSubmit}
          >
            <span className="">Save</span>
          </Button>
        </Grid>
      </Grid>
      <div className="my-8">
        <Card className="py-8 bg-white">
          <div className="mx-8 px-10 mb-8 bg-white">
            <Grid
              container
              spacing={5}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12}>
                <RichTextEditor
                  content={aturan || ""}
                  placeholder=""
                  handleContentChange={handleChange}
                />
              </Grid>
            </Grid>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Aturan;
