import { Button, ButtonGroup, Grid, Icon } from "@material-ui/core";
import { SimpleCard } from "matx";
import React, { Component, Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import Swal from "sweetalert2";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  editPotongan,
  getDetailPotongan,
} from "app/redux/actions/gaji/PotonganAction";
import { formatRupiah } from "app/utils/globalFunction";

const DetailPotongan = ({ getDetailPotongan, detailPotongan }) => {
  const [state, setState] = useState({
    loading: true,
    search: "",
    searchTgl: 0,
    page: 0,
    rowsPerPage: 10,
    selectedItem: "",
    nameClick: "",
    tambahAbsensi: false,
    editAbsensi: false,

    aksiClick: false,

    dataLanguage: [
      {
        label: "Indonesia",
        value: "ind",
      },
      {
        label: "English",
        value: "eng",
      },
    ],
    bahasa: "",
  });

  const { id } = useParams();
  const getData = () => {
    getDetailPotongan(id);
  };

  useEffect(() => {
    getData();
  }, [id]);

  return detailPotongan ? (
    <div className="m-sm-30">
      <h1 className="mb-20px">Detail Potongan</h1>
      <SimpleCard loading={false} currency="" saldo="">
        <div className="simple-card-content">
          <Fragment>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <h5>Nama</h5>
                <div>{detailPotongan?.name}</div>
              </Grid>
              <Grid item sm={6} xs={12} className="mb-20px">
                <h5>Periode</h5>
                <div>{detailPotongan?.periode}</div>
              </Grid>

              <Grid item sm={6} xs={12} className="mb-20px">
                <h5>Gaji Pokok</h5>
                <div>{detailPotongan?.gaji_pokok}</div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <h5>Tunjangan</h5>
                <div style={{ color: "#72BE42" }}>
                  {formatRupiah(detailPotongan?.tunjangan)}
                </div>
              </Grid>

              <Grid item sm={6} xs={12} className="mb-20px">
                <h5>Izin/Sakit/WFH</h5>
                <div>{detailPotongan?.izin} Hari</div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <h5>Tidak Hadir/Tanpa Keterangan</h5>
                <div>{detailPotongan?.tidak_hadir} Hari</div>
              </Grid>

              <Grid item sm={6} xs={12}>
                <h5>Hadir</h5>
                <div>{detailPotongan?.hadir} Hari</div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <h5>Potongan</h5>
                <div style={{ color: "#FF5C58" }}>
                  {formatRupiah(detailPotongan?.potongan)}
                </div>
              </Grid>
            </Grid>
          </Fragment>
        </div>
      </SimpleCard>
    </div>
  ) : (
    <div>wait</div>
  );
};

const mapStateToProps = (state) => {
  return {
    detailPotongan: state.gaji.detailPotongan,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDetailPotongan: (code) => dispatch(getDetailPotongan(code)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailPotongan);
