import {
  Button,
  ButtonGroup,
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import { SimpleCard } from 'matx';
import React, {
  Component,
  Fragment,
  useEffect,
  useState,
  useLayoutEffect,
} from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { formatTanggal } from 'app/utils/globalFunction';
import {
  getInformasiGaji,
  delInformasiGaji,
} from 'app/redux/actions/gaji/InformasiGajiAction';
import AbsensiForm from '../absensi/AbsensiForm';
import { Link } from 'react-router-dom';
import { MatxMenu } from 'matx';
import RenderTableGaji from './component/RenderTableGaji';
import { SelectText } from 'app/components';

const Gaji = ({ dataInformasiGaji, getInformasiGaji, delInformasiGaji }) => {
  const [state, setState] = useState({
    loading: true,
    search: '',
    searchTgl: 0,
    page: 0,
    rowsPerPage: 10,
    selectedItem: '',
    nameClick: '',
    tambahAbsensi: false,
    editAbsensi: false,

    aksiClick: false,

    dataLanguage: [
      {
        label: 'Indonesia',
        value: 'ind',
      },
      {
        label: 'English',
        value: 'eng',
      },
    ],
    bahasa: '',
  });

  const setPage = (page) => {
    setState({ ...state, page });
  };

  const setRowsPerPage = (event) => {
    setState({ ...state, rowsPerPage: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [pengajuan, setPengajuan] = useState(true);
  const [open, setOpen] = useState(false);

  const getData = () => {
    let params = `?search=${search}&unit_kerja=${status}`;
    if (status === '') {
      params = `?search=${search}`;
    } else if (search === '') {
      params = `?unit_kerja=${status}`;
    }

    getInformasiGaji(params);
  };

  useEffect(() => {
    getData();
  }, [status]);

  useLayoutEffect(() => {
    getData();
  }, []);

  const submitSearch = (e) => {
    if (e.keyCode == 13) {
      getData();
    }
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleChange = (e) => {
    setState({
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="m-sm-30">
      <Grid container spacing={1} justify="space-between" className="my-4 mb-8">
        <h1 className="font-semibold text-25 mt-4 ">Informasi Gaji</h1>
      </Grid>
      <SimpleCard loading={false} currency="" saldo="">
        <Fragment>
          <div
            className="d-flex items-center py-4 "
            style={{ justifyContent: 'flex-end', gap: '20px' }}
          >
            <TextField
              size="small"
              variant="outlined"
              className="karyawan-top-search"
              placeholder="Cari Nama Karyawan"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              onKeyDown={submitSearch}
              name="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
              }}
            />

            <Grid item xs={3} sm={2} className="d-flex align-items-end">
              <SelectText
                search
                unitKerja
                label="Status"
                handleChange={handleStatus}
                value={status}
              />
            </Grid>
          </div>
          <div className="w-full overflow-auto bg-white">
            <Table
              className="buku-kas-table"
              style={{
                borderTop: '1px #e6e5e5 solid',
                marginTop: '20px',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={1}>No</TableCell>
                  <TableCell colSpan={3}>Nama</TableCell>
                  <TableCell align="center" colSpan={2}>
                    Unit Kerja
                  </TableCell>
                  <TableCell align="center" colSpan={4}>
                    Gaji Total
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    No. Rekening
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    Aksi
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <RenderTableGaji
                  state={state}
                  data={dataInformasiGaji}
                  tableName="gaji"
                />
              </TableBody>
            </Table>
            <TablePagination
              className="px-16"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataInformasiGaji?.length ? dataInformasiGaji?.length : 0}
              rowsPerPage={state.rowsPerPage}
              labelRowsPerPage={'From'}
              page={state.page}
              backIconButtonProps={{
                'aria-label': 'Previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next page',
              }}
              backIconButtonText="Previous page"
              nextIconButtonText="Next page"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={setRowsPerPage}
            />
          </div>
        </Fragment>

        {state.nameClick && <renderDetail selectedItem={state.nameClick} />}

        {state.tambahAbsensi && <AbsensiForm formName="tambah" />}
        {state.editAbsensi && <AbsensiForm />}
      </SimpleCard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataInformasiGaji: state.gaji.dataInformasiGaji,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getInformasiGaji: (params) => dispatch(getInformasiGaji(params)),
    delInformasiGaji: (params) => dispatch(delInformasiGaji(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Gaji);
