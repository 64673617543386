import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Button,
  Chip,
} from "@material-ui/core";

import MenuKontrak from "./MenuKontrak";

const RenderTable = ({
  data,
  state,
  search,
  magang,
  handleDelete,
  handleStatusEdit,
}) => {
  const handleNumbering = () => {
    if (state.rowsPerPage === 5) {
      return state.page * 5;
    } else if (state.rowsPerPage === 10) {
      return state.page * 10;
    } else if (state.rowsPerPage === 25) {
      return state.page * 25;
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "28px",
      color: "#FFFFFF",
      flex: "none",
      width: "110px",
      height: "36px",
    },
    yellow: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "28px",
      color: "#FFFFFF",
      flex: "none",
      width: "110px",
      height: "36px",
      background: "#FBC02A",
    },
    red: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "28px",
      color: "#FFFFFF",
      flex: "none",
      width: "110px",
      height: "36px",
      background: "#FF5C58",
    },
  }));

  const classes = useStyles();

  return data?.length > 0 ? (
    data
      .slice(
        state.page * state.rowsPerPage,
        state.page * state.rowsPerPage + state.rowsPerPage
      )

      .map((item, index) => (
        <TableRow hover key={index}>
          <TableCell className="" align="center" colSpan={1}>
            <span className="karyawan-tabel-body-txt">
              {index + 1 + handleNumbering()}
            </span>
          </TableCell>
          <TableCell className="pl-3" colSpan={3}>
            <span className="karyawan-tabel-body-txt">
              {item.nama_karyawan}
            </span>
          </TableCell>
          <TableCell className="pl-3" align="center" colSpan={3}>
            <span className="karyawan-tabel-body-txt">{item.nama_signer}</span>
          </TableCell>
          <TableCell className="pl-3" align="center" colSpan={2}>
            <span className="karyawan-tabel-body-txt">
              {item.status === "diproses" ? (
                <Chip
                  className={classes.yellow}
                  color="primary"
                  label="Diproses"
                />
              ) : item.status === "ditolak" ? (
                <Chip
                  className={classes.red}
                  color="primary"
                  colorPrimary=""
                  label="Ditolak"
                />
              ) : (
                <Chip
                  className={classes.root}
                  color="primary"
                  label="Diterima"
                />
              )}
            </span>
          </TableCell>
          <TableCell className="pl-3" align="center" colSpan={2}>
            <Button className="karyawan-aksi-dot elevation-z0">
              <MenuKontrak
                item={item}
                path={`/administrasi/kontrak/edit/${item.kontrak_kerja_code}`}
                handleDelete={() => handleDelete(item.kontrak_kerja_code)}
                handleDiterima={() =>
                  handleStatusEdit(item.kontrak_kerja_code, "diterima")
                }
                handleDitolak={() =>
                  handleStatusEdit(item.kontrak_kerja_code, "ditolak")
                }
              />
            </Button>
          </TableCell>
        </TableRow>
      ))
  ) : (
    <TableRow hover>
      <TableCell colSpan={11} align="center">
        Data kosong
      </TableCell>
    </TableRow>
  );
};

const TableKontrak = ({
  search,
  magang = true,
  data,
  handleDelete,
  handleStatusEdit,
}) => {
  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 10,
  });

  const setPage = (page) => {
    setState({
      ...state,
      page,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const setRowsPerPage = (event) => {
    setState({
      ...state,
      rowsPerPage: event.target.value,
    });
  };

  return (
    <div className="w-full overflow-auto bg-white izincuti-tabs-slide">
      <p>{search}</p>
      <Table className="buku-kas-table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={1} align="center">
              <span className="karyawan-tabel-header-txt">No</span>
            </TableCell>
            <TableCell colSpan={3} className="pl-3">
              <span className="karyawan-tabel-header-txt">
                Nama Karyawan {magang && "Magang"}
              </span>
            </TableCell>
            <TableCell colSpan={3} className="" align="center">
              <span className="karyawan-tabel-header-txt">Nama Signer</span>
            </TableCell>
            <TableCell colSpan={2} className="" align="center">
              <span className="karyawan-tabel-header-txt">Status</span>
            </TableCell>
            <TableCell colSpan={2} className="" align="center">
              <span className="karyawan-tabel-header-txt">Aksi</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <RenderTable
            data={data}
            state={state}
            search={search}
            magang={magang}
            handleDelete={handleDelete}
            handleStatusEdit={handleStatusEdit}
          />
        </TableBody>
      </Table>

      <TablePagination
        className="px-16"
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data?.length ? data?.length : 0}
        rowsPerPage={state.rowsPerPage}
        labelRowsPerPage={"From"}
        page={state.page}
        backIconButtonProps={{
          "aria-label": "Previous page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next page",
        }}
        backIconButtonText="Previous page"
        nextIconButtonText="Next page"
        onPageChange={handleChangePage}
        onRowsPerPageChange={setRowsPerPage}
      />
    </div>
  );
};

export default TableKontrak;
