import { makeStyles } from "@material-ui/core/styles";
import {
  TableCell,
  TableRow,
  TableBody,
  Button,
  Chip,
} from "@material-ui/core";
import { formatRupiah } from "app/utils/globalFunction";

import Aksieye from "assets/component/Aksieye.svg";

const TableReimburseUser = ({ state, data, aksiClick }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "28px",
      color: "#FFFFFF",
      flex: "none",
      width: "110px",
      height: "36px",
    },
    yellow: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "28px",
      color: "#FFFFFF",
      flex: "none",
      width: "110px",
      height: "36px",
      background: "#FBC02A",
    },
    red: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "28px",
      color: "#FFFFFF",
      flex: "none",
      width: "110px",
      height: "36px",
      background: "#FF5C58",
    },
  }));

  const classes = useStyles();

  const handleNumbering = () => {
    if (state.rowsPerPage === 5) {
      return state.page * 5;
    } else if (state.rowsPerPage === 10) {
      return state.page * 10;
    } else if (state.rowsPerPage === 25) {
      return state.page * 25;
    }
  };

  return (
    <TableBody>
      {data?.length > 0 ? (
        data
          .slice(
            state.page * state.rowsPerPage,
            state.page * state.rowsPerPage + state.rowsPerPage
          )
          .map((item, index) => (
            <TableRow hover key={index}>
              <TableCell colSpan={1}>{index + 1 + handleNumbering()}</TableCell>
              <TableCell colSpan={3}>{item?.name}</TableCell>
              <TableCell align="center" colSpan={3}>
                {item?.jenis_reimburse}
              </TableCell>
              <TableCell align="center" colSpan={3}>
                {formatRupiah(item?.nominal)}
              </TableCell>
              <TableCell align="center" colSpan={3}>
                {item?.tanggal}
              </TableCell>
              <TableCell align="center" colSpan={3}>
                {item?.status.toLowerCase() === "diproses" ? (
                  <Chip
                    className={classes.yellow}
                    color="primary"
                    label="Diproses"
                  />
                ) : item?.status.toLowerCase() === "ditolak" ? (
                  <Chip
                    className={classes.red}
                    color="primary"
                    colorPrimary=""
                    label="Ditolak"
                  />
                ) : (
                  <Chip
                    className={classes.root}
                    color="primary"
                    label="Diterima"
                  />
                )}
              </TableCell>
              <TableCell align="center" colSpan={1}>
                <Button onClick={() => aksiClick(item?.reimburse_code)}>
                  <div className="dot-container">
                    <img src={Aksieye} alt="eye" />
                  </div>
                </Button>
              </TableCell>
            </TableRow>
          ))
      ) : (
        <TableRow hover>
          <TableCell colSpan={17} align="center">
            Data kosong
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default TableReimburseUser;
