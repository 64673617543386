import { useState } from "react";
import {
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Button,
} from "@material-ui/core";
import { formatTgl } from "app/utils/globalFunction";
import Modal from "./Modal";

const RenderTable = ({ state, statePage, izin, getData }) => {
  const handleNumbering = () => {
    if (statePage.rowsPerPage === 5) {
      return statePage.page * 5;
    } else if (statePage.rowsPerPage === 10) {
      return statePage.page * 10;
    } else if (statePage.rowsPerPage === 25) {
      return statePage.page * 25;
    }
  };

  return state.data?.length > 0 ? (
    state.data
      .slice(
        statePage.page * statePage.rowsPerPage,
        statePage.page * statePage.rowsPerPage + statePage.rowsPerPage
      )
      .filter((data) =>
        state.pengajuan
          ? data.status.toLowerCase() === "diproses"
          : data.status.toLowerCase() !== "diproses"
      )
      .map((item, index) => (
        <TableRow hover key={index}>
          <TableCell
            className="text-semiblack text-14"
            align="center"
            colSpan={1}
          >
            {index + 1 + handleNumbering()}
          </TableCell>
          <TableCell
            className="text-14 pl-3 text-semiblack"
            colSpan={izin ? 6 : 4}
          >
            {item?.name}
          </TableCell>
          <TableCell
            className="text-semiblack text-14 pl-3"
            align="center"
            colSpan={izin ? 4 : 3}
          >
            {item?.unit_kerja}
          </TableCell>
          {izin ? (
            <TableCell
              className="text-semiblack text-14 pl-3"
              align="center"
              colSpan={izin ? 4 : 3}
            >
              {item?.jenis_izin}
            </TableCell>
          ) : (
            <TableCell
              className="text-semiblack text-14 pl-3"
              align="center"
              colSpan={1}
            ></TableCell>
          )}
          <TableCell
            className="text-semiblack text-14 pl-3"
            align="center"
            colSpan={izin ? 8 : 5}
          >
            {formatTgl(item?.tanggal_mulai) === formatTgl(item?.tanggal_akhir)
              ? formatTgl(item?.tanggal_mulai)
              : formatTgl(item?.tanggal_mulai) +
                " - " +
                formatTgl(item?.tanggal_akhir)}
          </TableCell>
          <TableCell
            className="text-semiblack text-14 pl-2"
            align="center"
            colSpan={3}
          >
            <span
              className={
                item?.status.toLowerCase() === "diproses"
                  ? "status-diproses"
                  : item?.status.toLowerCase() === "diizinkan"
                  ? "status-diizinkan"
                  : "status-ditolak"
              }
            >
              {item?.status == ""
                ? ""
                : item?.status[0].toUpperCase() + item?.status.substring(1)}
            </span>
          </TableCell>
          <TableCell className="text-14 pl-2" align="center" colSpan={2}>
            <Modal item={item} getData={getData} izin={izin} />
          </TableCell>
        </TableRow>
      ))
  ) : (
    <TableRow hover>
      <TableCell colSpan={izin ? 28 : 19} align="center">
        Data kosong
      </TableCell>
    </TableRow>
  );
};

const IzinPengajuan = ({ state, izin = true, getData }) => {
  const [statePage, setStatePage] = useState({
    page: 0,
    rowsPerPage: 10,
  });

  const setPage = (page) => {
    setStatePage({
      ...statePage,
      page,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const setRowsPerPage = (event) => {
    setStatePage({
      ...statePage,
      rowsPerPage: event.target.value,
    });
  };

  return (
    <div className="w-full overflow-auto bg-white izincuti-tabs-slide">
      <Table className="buku-kas-table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={1} className="text-15" align="center">
              No
            </TableCell>
            <TableCell colSpan={izin ? 6 : 4} className="text-15 pl-3">
              Nama
            </TableCell>
            <TableCell
              colSpan={izin ? 4 : 3}
              className="text-15"
              align="center"
            >
              Unit Kerja
            </TableCell>
            {izin ? (
              <TableCell
                colSpan={izin ? 4 : 3}
                className="text-15"
                align="center"
              >
                Jenis Izin
              </TableCell>
            ) : (
              <TableCell
                colSpan={1}
                className="text-15"
                align="center"
              ></TableCell>
            )}
            <TableCell
              colSpan={izin ? 8 : 5}
              className="text-15"
              align="center"
            >
              Rentang Waktu
            </TableCell>
            <TableCell colSpan={3} className="text-15" align="center">
              Status
            </TableCell>
            <TableCell colSpan={2} className="text-15" align="center">
              Aksi
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <RenderTable
            getData={getData}
            state={state}
            izin={izin}
            statePage={statePage}
          />
        </TableBody>
      </Table>

      <TablePagination
        className="px-16 my-7"
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={state.data?.length ? state.data?.length : 0}
        rowsPerPage={statePage.rowsPerPage}
        labelRowsPerPage={"From"}
        page={statePage.page}
        backIconButtonProps={{
          "aria-label": "Previous page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next page",
        }}
        backIconButtonText="Previous page"
        nextIconButtonText="Next page"
        onPageChange={handleChangePage}
        onRowsPerPageChange={setRowsPerPage}
      />
    </div>
  );
};

export default IzinPengajuan;
