import { TableCell, TableRow, Button } from '@material-ui/core';
import Modal from 'app/views/izincuti/component/Modal';

const RenderTableDashboard = ({ data, state, getData }) => {
  const handleNumbering = () => {
    if (state.rowsPerPage === 5) {
      return state.page * 5;
    } else if (state.rowsPerPage === 10) {
      return state.page * 10;
    } else if (state.rowsPerPage === 25) {
      return state.page * 25;
    }
  };

  const handleTanggal = (str) => {
    var tgl = str.split('-');
    tgl = tgl.reverse();
    if (tgl[1] == '01') tgl[1] = 'Januari';
    if (tgl[1] == '02') tgl[1] = 'Februari';
    if (tgl[1] == '03') tgl[1] = 'Maret';
    if (tgl[1] == '04') tgl[1] = 'April';
    if (tgl[1] == '05') tgl[1] = 'Mei';
    if (tgl[1] == '06') tgl[1] = 'Juni';
    if (tgl[1] == '07') tgl[1] = 'Juli';
    if (tgl[1] == '08') tgl[1] = 'Agustus';
    if (tgl[1] == '09') tgl[1] = 'September';
    if (tgl[1] == '10') tgl[1] = 'Oktober';
    if (tgl[1] == '11') tgl[1] = 'November';
    if (tgl[1] == '12') tgl[1] = 'Desember';
    tgl = tgl.join(' ');

    return tgl;
  };

  return data?.length > 0 ? (
    data
      .slice(
        state.page * state.rowsPerPage,
        state.page * state.rowsPerPage + state.rowsPerPage
      )
      .map((item, index) => (
        <TableRow hover key={index}>
          <TableCell
            className="color-semiblack text-14"
            align="center"
            colSpan={1}
          >
            {index + 1 + handleNumbering()}
          </TableCell>
          <TableCell className="color-semiblack text-14 pl-3" colSpan={3}>
            {item.name}
          </TableCell>
          <TableCell
            className="color-semiblack text-14 pl-2"
            align="center"
            colSpan={3}
          >
            {item.unit_kerja}
          </TableCell>
          <TableCell
            className="color-semiblack text-14"
            align="center"
            colSpan={4}
          >
            {handleTanggal(item.tanggal_mulai)} -{' '}
            {handleTanggal(item.tanggal_akhir)}
          </TableCell>
          <TableCell
            className="color-semiblack text-14 pl-2"
            align="center"
            colSpan={3}
          >
            <span
              className={
                item.status.toLowerCase() === 'diproses'
                  ? 'status-diproses'
                  : item.status.toLowerCase() === 'diizinkan'
                  ? 'status-diizinkan'
                  : 'status-ditolak'
              }
            >
              <span className="">
                {item.status[0].toUpperCase() + item.status.substring(1)}
              </span>
            </span>
          </TableCell>
          <TableCell
            className="color-semiblack text-14 pl-1"
            align="center"
            colSpan={2}
          >
            <Modal
              item={item}
              getData={getData}
              izin={true}
              handleTanggal={handleTanggal}
            />
          </TableCell>
        </TableRow>
      ))
  ) : (
    <TableRow hover>
      <TableCell colSpan={16} align="center">
        Data kosong
      </TableCell>
    </TableRow>
  );
};

export default RenderTableDashboard;
