import {
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { SimpleCard } from "matx";
import React, { Fragment, useEffect, useState, useLayoutEffect } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import {
  getReimburseUser,
  delReimburse,
  editStatusReimburse,
} from "app/redux/actions/gaji/ReimburseAction";
import { SelectText, SelectWithTextAndValue } from "app/components";
import { Modal } from "app/components";
import { useParams } from "react-router-dom";
import TableReimburseUser from "../../component/TableReimburseUser";
import { getListReimburse } from "app/redux/actions/lainnya/JenisReimburseAction";

const ReimburseUser = ({
  dataReimburseUser,
  getReimburseUser,
  delReimburse,
  editStatusReimburse,
}) => {
  const [state, setState] = useState({
    loading: true,
    search: "",
    searchTgl: 0,
    page: 0,
    rowsPerPage: 10,
    selectedItem: "",
    nameClick: "",
    tambahAbsensi: false,
    editAbsensi: false,
    aksiClick: false,
    listJenisReimburse: [
      { value: "test", text: "Test" },
      { value: "", text: "Lihat Semua" },
    ],
    jenisReimburse: "",
  });

  const { id } = useParams();

  const [openModal, setOpenModal] = useState(false);

  const setPage = (page) => {
    setState({ ...state, page });
  };

  const setRowsPerPage = (event) => {
    setState({ ...state, rowsPerPage: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClose = () => {
    setOpenModal(false);

    getData();
  };
  const [status, setStatus] = useState("");
  const getData = () => {
    let params = `?jenis_reimburse=${state.jenisReimburse}&status=${status}`;

    getReimburseUser(id, params);
    getListReimburse("").then((res) => {
      const data = res.data.data.map((item) => ({
        text: item.jenis_reimburse,
        value: item.jenis_reimburse_code,
      }));

      data.push({ text: "Lihat Semua", value: "" });

      console.log(data);

      setState((prev) => ({
        ...prev,
        listJenisReimburse: data,
      }));
    });
  };

  useEffect(() => {
    getData();
  }, [status, state.jenisReimburse]);

  useLayoutEffect(() => {
    getData();
  }, []);

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleTerima = (id) => {
    editStatusReimburse({
      reimburse_code: id,
      status: "diterima",
    })
      .then(() => {
        Swal.fire(
          "Success!",
          "Data Status Reimburse berhasil disimpan",
          "success"
        );
        getData();
        setOpenModal(false);
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi",
          false
        );
      });
  };

  const [openModalTolak, setOpenModalTolak] = useState(false);

  const handleTolakModal = () => {
    setOpenModalTolak(true);
  };

  const handleTolak = (id, alasan) => {
    editStatusReimburse({
      reimburse_code: id,
      status: "ditolak",
      alasan: alasan,
    })
      .then(() => {
        Swal.fire(
          "Success!",
          "Data Status Reimburse berhasil disimpan",
          "success"
        );
        getData();
        setOpenModal(false);
      })
      .catch((err) => {
        let error = err?.response?.data;
        Swal.fire(
          Array.isArray(error?.data)
            ? error?.data[0]
            : "Gagal menyimpan data, coba beberapa saat lagi",
          false
        );
      });
  };

  const [dataDetail, setDataDetail] = useState({});

  const handleDetail = (e) => {
    setOpenModal(true);

    const dataFilter = dataReimburseUser.find(
      (item) => item.reimburse_code === e
    );
    setDataDetail(dataFilter);

    console.log("dataDetail", dataDetail);
  };

  const handleChange = (e) => {
    setState({
      [e.target.name]: e.target.value,
    });
  };
  const statusSearch = ["Diproses", "Ditolak", "Diterima"];

  return (
    <div className="m-sm-30">
      <Grid container spacing={1} justify="space-between" className="my-4 mb-8">
        <h1 className="mt-4 font-semibold text-25 ">
          List Reimburse {dataReimburseUser[0]?.name}
        </h1>
      </Grid>
      <SimpleCard loading={false} currency="" saldo="">
        <Fragment>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
            className="py-4 "
          >
            <Grid item xs={3}>
              <SelectWithTextAndValue
                dataSelect={state.listJenisReimburse}
                state={state}
                setState={setState}
                label="Jenis Reimburse"
                name="jenisReimburse"
                width="100%"
              />
            </Grid>
            <Grid item xs={2}>
              <SelectText
                dataSelect={statusSearch}
                handleChange={handleStatus}
                value={status}
                search
                label="Status"
              />
            </Grid>
          </Grid>
          <div className="w-full overflow-auto bg-white">
            <Table
              className="buku-kas-table"
              style={{
                borderTop: "1px #e6e5e5 solid",
                marginTop: "20px",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={1}>No</TableCell>
                  <TableCell colSpan={3}>Nama</TableCell>
                  <TableCell align="center" colSpan={3}>
                    Jenis
                  </TableCell>
                  <TableCell align="center" colSpan={3}>
                    Nominal
                  </TableCell>
                  <TableCell align="center" colSpan={3}>
                    Tanggal
                  </TableCell>
                  <TableCell align="center" colSpan={3}>
                    Status
                  </TableCell>
                  <TableCell align="center" colSpan={1}>
                    Aksi
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableReimburseUser
                state={state}
                data={dataReimburseUser}
                aksiClick={handleDetail}
              />
            </Table>
            <TablePagination
              className="px-16"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataReimburseUser?.length ? dataReimburseUser?.length : 0}
              rowsPerPage={state.rowsPerPage}
              labelRowsPerPage={"From"}
              page={state.page}
              backIconButtonProps={{
                "aria-label": "Previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next page",
              }}
              backIconButtonText="Previous page"
              nextIconButtonText="Next page"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={setRowsPerPage}
            />
          </div>
        </Fragment>
      </SimpleCard>
      <Modal
        open={openModal}
        handleClose={handleClose}
        modalName="detailReimburse"
        data={dataDetail}
      />
      <Modal
        open={openModal}
        setClose={setOpenModal}
        modalName="detailReimburse"
        data={dataDetail}
        getData={getData}
        edit
        handleTerima={handleTerima}
        handleTolakModal={handleTolakModal}
      />
      <Modal
        open={openModalTolak}
        setClose={setOpenModalTolak}
        modalName="tolakreimburse"
        getData={getData}
        handleTolak={handleTolak}
        data={dataDetail}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataReimburseUser: state.gaji.dataReimburseUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getReimburseUser: (path, params) =>
      dispatch(getReimburseUser(path, params)),
    delReimburse: (params) => dispatch(delReimburse(params)),
    editStatusReimburse: (params) => dispatch(editStatusReimburse(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReimburseUser);
