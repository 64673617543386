import {
  Button,
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import { SimpleCard } from 'matx';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getRekapCutiKaryawan } from 'app/redux/actions/IzinCutiAction';
import Aksieye from '../../../assets/component/Aksieye.svg';
import IzinCutiDatePicker from './component/IzinCutiDatePicker';

class RekapCutiKaryawan extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      search: '',
      searchTgl: new Date(),
      page: 0,
      rowsPerPage: 10,
    };
  }

  setPage = (page) => {
    this.setState({ page });
  };

  setRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  getData = () => {
    const { getRekapCutiKaryawan } = this.props;

    let dateFormat = JSON.stringify(this.state.searchTgl);
    dateFormat = dateFormat.slice(1, 5);
    const params = `?tahun=${dateFormat}`;
    getRekapCutiKaryawan(params);
  };

  componentDidMount = () => {
    this.getData();
  };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevState.searchTgl !== this.state.searchTgl) {
      return this.state.searchTgl;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      const { getDataAbsen } = this.props;
      let dateFormat = JSON.stringify(this.state.searchTgl);
      dateFormat = dateFormat.slice(1, 11);

      this.getData();
    } else {
    }
  }

  renderTable2 = () => {
    const { page, rowsPerPage } = this.state;
    const { dataRekapCutiKaryawan, addKaryawanAbsensi } = this.props;

    const handleNumbering = () => {
      if (rowsPerPage === 5) {
        return page * 5;
      } else if (rowsPerPage === 10) {
        return page * 10;
      } else if (rowsPerPage === 25) {
        return page * 25;
      }
    };
    return dataRekapCutiKaryawan?.length > 0 ? (
      dataRekapCutiKaryawan
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => {
          return (
            <TableRow hover key={index}>
              <TableCell
                className="font-normal text-14 pl-3 py-7"
                colSpan={1}
                style={{ color: '#0A0A0A' }}
              >
                {index + 1 + handleNumbering()}
              </TableCell>

              <TableCell
                className="font-normal text-14  py-7"
                colSpan={3}
                style={{ color: '#0A0A0A' }}
              >
                {item.name}
              </TableCell>
              <TableCell
                align="center"
                className="font-normal text-14  py-7"
                colSpan={3}
                style={{ color: '#0A0A0A' }}
              >
                {item.saldo_cuti}
              </TableCell>
              <TableCell
                align="center"
                className="font-normal text-14  py-7"
                colSpan={3}
                style={{ color: '#0A0A0A' }}
              >
                {item.sedang_diajukan == 0 ? '-' : item.sedang_diajukan}
              </TableCell>
              <TableCell
                align="center"
                className="font-normal text-14  py-7"
                colSpan={3}
                style={{ color: '#0A0A0A' }}
              >
                {item.cuti_terpakai}
              </TableCell>
            </TableRow>
          );
        })
    ) : (
      <TableRow hover>
        <TableCell
          className="font-medium text-14  py-5"
          colSpan={13}
          style={{ color: '#0A0A0A' }}
          align="center"
        >
          Data kosong
        </TableCell>
      </TableRow>
    );
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { search, page, rowsPerPage } = this.state;
    const { dataRekapCutiKaryawan } = this.props;
    return (
      <div className="m-sm-30">
        <>
          <h1 className="my-4 font-semibold text-25">
            Informasi Jatah Cuti Karyawan
          </h1>
          <SimpleCard>
            <Fragment>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div
                    className="flex items-center py-5"
                    style={{ justifyContent: 'flex-end' }}
                  >
                    <IzinCutiDatePicker
                      state={this.state}
                      setState={this.setState.bind(this)}
                      name="searchTgl"
                      tipe="yyyy"
                    />
                  </div>
                </Grid>
              </Grid>
              <div className="w-full overflow-auto bg-white">
                <Table className="buku-kas-table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        colSpan={1}
                        className="font-medium text-15 py-6"
                        style={{ color: '#0a0a0a' }}
                      >
                        No
                      </TableCell>

                      <TableCell
                        colSpan={3}
                        className="font-medium text-15 py-6"
                        style={{ color: '#0a0a0a' }}
                      >
                        Karyawan
                      </TableCell>

                      <TableCell
                        align="center"
                        colSpan={3}
                        className="font-medium text-15 py-6 text-green"
                      >
                        Saldo Cuti
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={3}
                        className="font-medium text-15 py-6 text-yellow"
                      >
                        Sedang Diajukan
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={3}
                        className="font-medium text-15 py-6 text-red"
                      >
                        Cuti Terpakai
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{this.renderTable2()}</TableBody>
                </Table>
                <TablePagination
                  className="px-16"
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={
                    dataRekapCutiKaryawan?.length
                      ? dataRekapCutiKaryawan?.length
                      : 0
                  }
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage={'From'}
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'Previous page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next page',
                  }}
                  backIconButtonText="Previous page"
                  nextIconButtonText="Next page"
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.setRowsPerPage}
                />
              </div>
            </Fragment>
          </SimpleCard>
        </>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataRekapCutiKaryawan: state.izincuti.dataRekapCutiKaryawan,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRekapCutiKaryawan: (params) => dispatch(getRekapCutiKaryawan(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RekapCutiKaryawan);
