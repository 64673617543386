import { API, setAuthToken } from "app/config/api";
export const GET_ATURAN = "GET_ATURAN";

export const getAturan = () => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get("/user/akun/aturan_kebijakan")
      .then((res) => {
        dispatch({
          type: GET_ATURAN,
          payload: res.data.data.aturan.content || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_ATURAN,
          payload: [],
        });
      });
  };
};

export const saveAturan = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/akun/aturan", body);
  /*
    		- body :
			- privacy_policy: <p>isi privacy policy</p>
  */
};
