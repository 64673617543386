import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { SimpleCard } from "matx";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getRiwayatAbsensiKaryawan,
  addKaryawanAbsensi,
  editKaryawanAbsensi,
} from "app/redux/actions/AbsenAction";
import Aksieye from "../../../assets/component/Aksieye.svg";
import AbsensiDatePicker from "./component/AbsensiDatePicker";
import ModalAbsensiHadir from "./component/modal/ModalAbsensiHadir";
import ModalTextAbsensi from "./component/modal/ModalTextAbsensi";
import { Link } from "react-router-dom";

class DetailAbsensiKaryawanTable extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      searchTgl: new Date(),
      page: 0,
      rowsPerPage: 10,
      jam_masuk: "00:00",
      jam_pulang: "00:00",
      catatan: "",
    };
  }

  setPage = (page) => {
    this.setState({ page });
  };

  setRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  getData = (id) => {
    const { getRiwayatAbsensiKaryawan } = this.props;

    let dateFormat = JSON.stringify(this.state.searchTgl)
      .slice(1, 8)
      .split("-")
      .reverse()
      .join("-");

    var params;
    params = `/${id}?bulan_tahun=${dateFormat}`;
    getRiwayatAbsensiKaryawan(params);
  };

  componentDidMount = () => {
    const { dataRiwayatAbsensiKaryawan } = this.props;
    const id = this.props.match.params.id;
    this.getData(id);
  };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevState.searchTgl !== this.state.searchTgl) {
      return this.state.searchTgl;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const id = this.props.match.params.id;

    if (snapshot !== null) {
      this.getData(id);
    }
  }

  handleTanggal = (tanggal) => {
    var newTgl = tanggal.slice(5, 11).split("-").reverse();
    if (newTgl[1] == "01") newTgl[1] = "Januari";
    if (newTgl[1] == "02") newTgl[1] = "Februari";
    if (newTgl[1] == "03") newTgl[1] = "Maret";
    if (newTgl[1] == "04") newTgl[1] = "April";
    if (newTgl[1] == "05") newTgl[1] = "Mei";
    if (newTgl[1] == "06") newTgl[1] = "Juni";
    if (newTgl[1] == "07") newTgl[1] = "Juli";
    if (newTgl[1] == "08") newTgl[1] = "Agustus";
    if (newTgl[1] == "09") newTgl[1] = "September";
    if (newTgl[1] == "10") newTgl[1] = "Oktober";
    if (newTgl[1] == "11") newTgl[1] = "November";
    if (newTgl[1] == "12") newTgl[1] = "Desember";

    return newTgl.join(" ");
  };

  renderTable2 = () => {
    const id = this.props.match.params.id;
    const { page, rowsPerPage } = this.state;
    const {
      dataRiwayatAbsensiKaryawan,
      addKaryawanAbsensi,
      editKaryawanAbsensi,
    } = this.props;

    const handleNumbering = () => {
      if (rowsPerPage === 5) {
        return page * 5;
      } else if (rowsPerPage === 10) {
        return page * 10;
      } else if (rowsPerPage === 25) {
        return page * 25;
      }
    };

    return dataRiwayatAbsensiKaryawan.data?.length > 0 ? (
      dataRiwayatAbsensiKaryawan.data
        .sort((a, b) => new Date(b.tanggal) - new Date(a.tanggal))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => {
          return (
            <TableRow hover key={index}>
              <TableCell
                className="font-normal text-14  text-body text-semiblack"
                colSpan={1}
              >
                {index + 1 + handleNumbering()}
              </TableCell>
              <TableCell
                className="font-normal text-14  text-body text-semiblack"
                colSpan={2}
              >
                {this.handleTanggal(item.tanggal)}
              </TableCell>
              <TableCell align="center" colSpan={3}>
                {item.status === "" ? (
                  <div className="button-container-kehadiran">
                    <ModalAbsensiHadir
                      hadir={false}
                      state={this.state}
                      setState={this.setState.bind(this)}
                      name="jam_pulang"
                      item={{ ...item, id }}
                      addKaryawanAbsensi={addKaryawanAbsensi}
                      getData={this.getData.bind(this)}
                    />
                    <ModalAbsensiHadir
                      hadir={true}
                      state={this.state}
                      setState={this.setState.bind(this)}
                      name="jam_masuk"
                      item={{ ...item, id }}
                      addKaryawanAbsensi={addKaryawanAbsensi}
                      disableJamMasuk={false}
                      disableJamPulang={true}
                      getData={this.getData.bind(this)}
                    />
                  </div>
                ) : item.status.toLowerCase() === "hadir" ? (
                  <span className="font-medium text-primary text-14 ">
                    Hadir
                  </span>
                ) : item.status.toLowerCase() === "cuti" ? (
                  <span className="font-medium text-semiblack text-14 ">
                    Cuti
                  </span>
                ) : item.status.toLowerCase() === "izin" ? (
                  <span className="font-medium text-semiblack text-14 ">
                    Izin
                  </span>
                ) : item.status.toLowerCase() === "terlambat" ? (
                  <ModalTextAbsensi
                    terlambat={true}
                    state={this.state}
                    setState={this.setState.bind(this)}
                    name="jam_masuk"
                    item={{ ...item, id }}
                    editKaryawanAbsensi={editKaryawanAbsensi}
                    disableJamMasuk={false}
                    disableJamPulang={true}
                    getData={this.getData.bind(this)}
                  />
                ) : (
                  <ModalTextAbsensi
                    terlambat={false}
                    state={this.state}
                    setState={this.setState.bind(this)}
                    name="jam_masuk"
                    item={{ ...item, id }}
                    editKaryawanAbsensi={editKaryawanAbsensi}
                    disableJamMasuk={false}
                    disableJamPulang={true}
                    getData={this.getData.bind(this)}
                  />
                )}
              </TableCell>
              <TableCell
                align="center"
                className="font-medium text-14  text-semiblack"
                colSpan={2}
              >
                {item.jam_masuk === "" ? "-" : item.jam_masuk.slice(0, 5)}
              </TableCell>
              <TableCell
                align="center"
                className="font-medium text-14  text-semiblack"
                colSpan={2}
              >
                {item.status.toLowerCase() == "tidak hadir" ? (
                  "-"
                ) : item.jam_masuk === "" ? (
                  "-"
                ) : item.jam_pulang === "" ? (
                  <ModalAbsensiHadir
                    hadir={true}
                    state={this.state}
                    setState={this.setState.bind(this)}
                    name="jam_pulang"
                    item={{ ...item, id }}
                    editKaryawanAbsensi={editKaryawanAbsensi}
                    disableJamMasuk={true}
                    disableJamPulang={false}
                    getData={this.getData.bind(this)}
                  />
                ) : (
                  item.jam_pulang.slice(0, 5)
                )}
              </TableCell>
              <TableCell
                align="center"
                className="font-medium text-14  text-semiblack"
                colSpan={1}
              >
                <Link to={`/absensi/detail/${item.absensi_karyawan_code}`}>
                  <div className="dot-container">
                    <img src={Aksieye} alt="eye" />
                  </div>
                </Link>
              </TableCell>
            </TableRow>
          );
        })
    ) : (
      <TableRow hover>
        <TableCell
          className="font-medium text-14  text-semiblack"
          colSpan={13}
          align="center"
        >
          Data kosong
        </TableCell>
      </TableRow>
    );
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSearch = (e) => {
    if (e.keyCode == 13) {
      this.getData();
    }
  };

  render() {
    const { page, rowsPerPage } = this.state;
    const { dataRiwayatAbsensiKaryawan } = this.props;
    return (
      <div className="m-sm-30">
        <h1 className="font-semibold text-25 my-8 text-semiblack">
          Kehadiran {dataRiwayatAbsensiKaryawan.name.name}
        </h1>
        <SimpleCard>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item lg={6} sm={12}>
              <div
                className="flex items-center pb-2"
                style={{ justifyContent: "flex-end" }}
              >
                <AbsensiDatePicker
                  state={this.state}
                  setState={this.setState.bind(this)}
                  name="searchTgl"
                  tipe="MMMM yyyy"
                />
              </div>
            </Grid>
          </Grid>
          <div className="w-full overflow-auto bg-white">
            <Table
              className="buku-kas-table"
              style={{
                borderTop: "1px #e6e5e5 solid",
                marginTop: "20px",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={1}
                    className="py-5 font-medium text-15 text-semiblack"
                  >
                    No
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    className="py-5 font-medium text-15 text-semiblack"
                  >
                    Tanggal
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={3}
                    className="py-5 font-medium text-15 text-semiblack"
                  >
                    Kehadiran
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={2}
                    className="py-5 font-medium text-15 text-semiblack"
                  >
                    Jam Masuk
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={2}
                    className="py-5 font-medium text-15 text-semiblack"
                  >
                    Jam Pulang
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={1}
                    className="py-5 font-medium text-15 text-semiblack"
                  >
                    Aksi
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{this.renderTable2()}</TableBody>
            </Table>
            <TablePagination
              className="px-16"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={
                dataRiwayatAbsensiKaryawan.data?.length
                  ? dataRiwayatAbsensiKaryawan.data?.length
                  : 0
              }
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={"From"}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next page",
              }}
              backIconButtonText="Previous page"
              nextIconButtonText="Next page"
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.setRowsPerPage}
            />
          </div>
        </SimpleCard>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataRiwayatAbsensiKaryawan: state.absen.dataRiwayatAbsensiKaryawan,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRiwayatAbsensiKaryawan: (params) =>
      dispatch(getRiwayatAbsensiKaryawan(params)),
    addKaryawanAbsensi: (body) => dispatch(addKaryawanAbsensi(body)),
    editKaryawanAbsensi: (body) => dispatch(editKaryawanAbsensi(body)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailAbsensiKaryawanTable);
