import Swal from "sweetalert2";

export const formatRupiah = (angka, prefix, noPrefix) => {
  if (angka !== undefined) {
    var number_string = angka.toString().replace(/[^,\d]/g, "");
    var split = number_string.split(","),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      var separator = sisa ? "." : "";
      rupiah += separator + ribuan.join(".");
    }
    rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
    if (!noPrefix) {
      let result =
        prefix === undefined ? "Rp " + rupiah : rupiah ? prefix + rupiah : "";
      return angka < 0 ? "-" + result : result;
    } else {
      let result = angka < 0 ? "-" + rupiah : rupiah;
      return result;
    }
  } else {
    return 0;
  }
};

export function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${day}/${month}/${year}`;
}

export function formatTgl(dateString, type = " ") {
  const date = new Date(dateString);
  const year = date.getFullYear();
  var month = (date.getMonth() + 1).toString().padStart(2, "0");
  if (month == 1) month = "Januari";
  if (month == 2) month = "Februari";
  if (month == 3) month = "Maret";
  if (month == 4) month = "April";
  if (month == 5) month = "Mei";
  if (month == 6) month = "Juni";
  if (month == 7) month = "Juli";
  if (month == 8) month = "Agustus";
  if (month == 9) month = "September";
  if (month == 10) month = "Oktober";
  if (month == 11) month = "November";
  if (month == 12) month = "Desember";
  const day = date.getDate().toString().padStart(2, "0");
  return `${day}${type}${month}${type}${year}`;
}

export function maskCurrencyInput(numStr) {
  const num = parseFloat(numStr.replace(",", "."));
  if (isNaN(num)) return numStr;
  const parts = num.toFixed(0).toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(".");
}

export const formatDollar = (angka) => {
  // console.log("default", angka);
  // console.log(
  //   "angkaa",
  //   angka?.toString()?.replace("$", "")?.replace(".00", "")
  // );
  let dollar = angka?.toString()?.replace("$", "")?.replace(".00", "");
  // console.log("dollar", dollar);
  let result = 0;
  if (angka !== undefined && angka !== 0) {
    result = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(dollar);
  }
  return result?.toString().replaceAll(".00", "");
};

export const formatHarga = (angka) => {
  if (angka !== undefined) {
    var number_string = angka.toString().replace(/[^,\d]/g, "");
    var split = number_string.split(","),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      var separator = sisa ? "." : "";
      rupiah += separator + ribuan.join(".");
    }
    rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
    let result = angka < 0 ? "-" + rupiah : rupiah;
    return result;
  }
};

export const formatTanggal = (tanggal) => {
  const monthNames = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const d = new Date(tanggal);
  const ye = d.getFullYear();
  const mo = d.getMonth();
  const da = d.getDate();
  return `${da} ${monthNames[mo]} ${ye}`;
};

export const konversiRomawi = (nomor) => {
  var desimal = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
  var romawi = [
    "M",
    "CM",
    "D",
    "CD",
    "C",
    "XC",
    "L",
    "XL",
    "X",
    "IX",
    "V",
    "IV",
    "I",
  ];

  var hasil = "";

  for (var index = 0; index < desimal.length; index++) {
    while (desimal[index] <= nomor) {
      hasil += romawi[index];
      nomor -= desimal[index];
    }
  }
  return hasil;
};

export const capitalizeString = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const showAlert = (text, success, success_path = "") => {
  return Swal.fire({
    title: success ? "Berhasil" : "Oopss!",
    text: text,
    imageUrl: `/assets/images/icon/${success ? "ic_success" : "ic_error"}.svg`,
    imageWidth: 92,
    imageHeight: 92,
    confirmButtonText: "Ok",
    confirmButtonColor: "#0083E2",
  }).then((res) => {
    if (res.isConfirmed && success) {
      window.location.href = success_path;
    }
  });
};
